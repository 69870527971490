/**
 * Manages Annoucements that are displayed in the `announcements` div. A new announcement can be
 * add()ed, and the div will be displayed only when announcements are enable()d and there is a
 * non-expired announcement to display.
 */
import Base = require("Everlaw/Base");

class Announcement extends Base.Object {
    static ShowFor = {
        ALL_CASES: "ALL_CASES",
        NON_PARTNER_CASES: "NON_PARTNER_CASES",
        PARTNER_CASES: "PARTNER_CASES", // requires partnerId to be set
        RELEASE_SUBSCRIBERS: "RELEASE_SUBSCRIBERS",
        IE_BROWSER: "IE_BROWSER",
        ORG_DB_CASES: "ORG_DB_CASES",
        LEGAL_HOLD_SUSPENSION: "LEGAL_HOLD_SUSPENSION",
        CASE_SUBSCRIPTION: "CASE_SUBSCRIPTION",
    };
    get className() {
        return "Announcement";
    }
    startTime: number;
    endTime: number;
    dismissible: boolean;
    suspended: boolean;
    message: string;
    userId: number;
    showFor: string;
    partnerId: number;
    organizationIds: number[];
    databaseIds: number[];
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
    }
    isOverdue() {
        return this.showFor === Announcement.ShowFor.ORG_DB_CASES;
    }
}
export { Announcement };
