import * as Multiplex from "Everlaw/Multiplex";
import { CURRENT_ORG } from "Everlaw/Organization";
import * as Project from "Everlaw/Project";
import * as Rest from "Everlaw/Rest";
import { Recommendation } from "Everlaw/SmartOnboarding/Recommendation";
import { Recommendations } from "Everlaw/SmartOnboarding/RecommendationConstants";
import {
    fromCurrentPage,
    RecommendationNavigationPage,
} from "Everlaw/SmartOnboarding/RecommendationNavigationPage";
import {
    getActiveRecommendation,
    setActiveRecommendation,
    setRecommendationsLoaded,
} from "Everlaw/SmartOnboarding/RecommendationSharedVariables";
import {
    RecommendationStatus,
    RecommendationStatusRestData,
} from "Everlaw/SmartOnboarding/RecommendationStatus";
import { canInitializeRecommendations } from "Everlaw/SmartOnboarding/RecommendationUtil";
import * as User from "Everlaw/User";

/**
 * Loads all the user's {@link Recommendation recommendations} into the frontend. We need to have
 * access to this data on page load because we may use it to show other recommendations.
 */
async function loadAllRecommendations(): Promise<RecommendationStatusRestData[]> {
    if (Project.CURRENT) {
        return await Rest.get("recommendation/getAllRecommendations.rest");
    }
    return await Rest.get("/recommendation/getAllRecommendations.rest", {
        orgId: CURRENT_ORG?.id,
    });
}

/**
 * Loads the next {@link Recommendation} we'll show the user. This can be a recommendation the user
 * is currently in the process of viewing or one that hasn't been shown yet. If we're not currently
 * on a page that supports recommendations,
 */
async function loadActiveOrPendingRecommendation(
    supportedPage: RecommendationNavigationPage,
): Promise<RecommendationStatusRestData | undefined> {
    // We're in the context of a project, so we should hit the project controller endpoint.
    if (Project.CURRENT) {
        const getUrl = Project.url(
            Project.CURRENT.id,
            "recommendation/getActiveOrPendingRecommendation.rest",
        );
        return await Rest.get(getUrl, {
            supportedPage,
        });
    }
}

/**
 * Reach out to the backend for the next {@link Recommendation} to activate. This could be a
 * recommendation the user hasn't seen yet, a recommendation the user is in the process of
 * viewing, or no recommendation if the user has none that can be shown on the current page.
 *
 * This assumes there are no other recommendations currently active. We know this because
 * setting the active recommendation is gated by setting {@link recommendationsLoaded}, which
 * doesn't happen until after we've called this method.
 */
async function activateNextRecommendation(
    recommendationToActivate: Recommendation,
    recommendationStatus: RecommendationStatusRestData,
): Promise<void> {
    recommendationStatus.step = recommendationStatus.step ?? 0;
    recommendationStatus.history = recommendationStatus.history ?? [];

    if (recommendationStatus.history.length > 0 && recommendationStatus.uriFrom) {
        recommendationToActivate
            .getStep(recommendationStatus.history[recommendationStatus.history.length - 1])
            .registerRedirectBack(recommendationStatus.uriFrom);
    }
    setActiveRecommendation(recommendationToActivate);
    setRecommendationsLoaded(true);
    await recommendationToActivate.getStep(recommendationStatus.step).activate();
}

function initMultiplexSubscription(): void {
    const url = `${Project.CURRENT ? "" : "/"}recommendation/subscribeToRecommendations.rest`;
    Multiplex.subscribe({
        url,
        success: (sub: Multiplex.Subscription) => {
            sub.begin(async (recommendationStatus: RecommendationStatus) => {
                // Updates from different projects are not relevant to us if we aren't on a page
                // in the context of that project.
                if (
                    recommendationStatus.projectId
                    && recommendationStatus.projectId !== Project.CURRENT?.id
                ) {
                    return;
                }
                const recommendation: Recommendation =
                    Recommendations[recommendationStatus.recommendation];
                recommendation.setStatus(recommendationStatus);
                const shouldActivate = await recommendation.shouldActivate();
                if (!shouldActivate || getActiveRecommendation()) {
                    return;
                }
                await recommendation.activate();
            });
        },
    });
}

/**
 * This should be called after we've loaded recommendations into the page.
 */
function shouldActivateIntroRecommendation(): boolean {
    const introShown =
        Recommendations.SMART_ONBOARDING_INTRO.isTriggeredUnsafe()
        && Recommendations.SMART_ONBOARDING_INTRO.isShownUnsafe();
    return (
        !User.me.impersonatorUsername
        && !Recommendations.WELCOME_TO_EVERLAW.isTriggeredUnsafe()
        && !introShown
    );
}

interface NextRecommendationData {
    toActivate?: Recommendation;
    nextRecommendationStatus?: RecommendationStatusRestData;
}

async function loadNextRecommendation(
    supportedPage: RecommendationNavigationPage,
): Promise<NextRecommendationData> {
    const nextRecommendationStatus = await loadActiveOrPendingRecommendation(supportedPage);
    let toActivate: Recommendation | undefined;
    if (nextRecommendationStatus) {
        Recommendations[nextRecommendationStatus.recommendation].setStatus(
            nextRecommendationStatus,
        );
        toActivate = Recommendations[nextRecommendationStatus.recommendation];
    }
    return { toActivate, nextRecommendationStatus };
}

/**
 * The general flow of execution here is:
 *  1. Make a request for the user's recommendations and load them into the frontend cache, then
 *     load the next recommendation to show the user if one exists.
 *  2. Activate the next recommendation. At this point, we'll mark {@link #recommendationsLoaded}
 *     and proceed to start showing recommendations at whatever point they're triggered.
 *  3. Recommendations must wait on {@link #recommendationsLoaded} to be true, and
 *     {@link #getActiveRecommendation} to be null before they're allowed to proceed with being
 *     shown.
 *  4. Initialize the multiplex subscription. This follows the rules set by the previous steps to
 *     prevent an update from preventing a user from seeing a recommendation they're in the process
 *     of viewing.
 */
export async function init(): Promise<void> {
    // No reason to load recommendations on a page we can't show them on. Additionally, we won't
    // show recommendations on pages we can't initialize the multiplex subscription on. This may
    // be worth revisiting if we want to show a recommendation on the user profile page, otherwise
    // this should only affect pages the user doesn't have permission to access.
    const supportedPage = fromCurrentPage();
    if (!supportedPage || !canInitializeRecommendations(supportedPage)) {
        return;
    }
    // Load recommendations.
    const statusData = await loadAllRecommendations();
    statusData.forEach((status) => Recommendations[status.recommendation].setStatus(status));
    // Activate the next pending recommendation (if one exists).
    if (shouldActivateIntroRecommendation()) {
        setRecommendationsLoaded(true);
        await Recommendations.SMART_ONBOARDING_INTRO.trigger();
    } else {
        const { toActivate, nextRecommendationStatus } =
            await loadNextRecommendation(supportedPage);
        if (toActivate && nextRecommendationStatus) {
            await activateNextRecommendation(toActivate, nextRecommendationStatus);
        } else {
            // This is set by activateNextRecommendation as well, and we should only call it once.
            setRecommendationsLoaded(true);
        }
    }
    // Load the multiplex subscription and activate a recommendation if there's one pending.
    initMultiplexSubscription();
}
