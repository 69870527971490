import { Constants as C } from "core";
import Duration = require("Everlaw/Duration");
import Util = require("Everlaw/Util");

export interface OngoingJobInfoJson {
    endEta: number;
    deferWeight: number;
    totalWeight: number;
    queuedCount: number;
    totalCount: number;
    queues: string[];
}

/**
 * Information about an ongoing job in the work queue.
 */
export class OngoingJobInfo {
    endEta: number;
    deferWeight: number;
    totalWeight: number;
    queuedCount: number;
    totalCount: number;
    queues: string[];

    show = false;

    constructor(params: OngoingJobInfoJson) {
        Object.assign(this, params);
    }

    display(): string {
        const frac = this.fraction();
        if (this.show || this.deferWeight < 0.25 * this.totalWeight) {
            this.show = true;
            return `${frac}, ${this.eta()}`;
        }
        return frac;
    }

    fraction(): string {
        return `${Util.num(this.completed())} / ${Util.num(this.totalCount)} docs`;
    }

    eta(): string {
        if (this.endEta < C.MIN) {
            return "<1m";
        }
        return Duration.HR_MIN.format(this.endEta);
    }

    hasQueuedWork(queue: string): boolean {
        return !!this.queues && this.queues.indexOf(queue) >= 0;
    }

    completed(): number {
        return this.totalCount - this.queuedCount;
    }
}
