import Base = require("Everlaw/Base");
import Dom = require("Everlaw/Dom");
import Message = require("Everlaw/Messaging/Message");
import ShareableObject = require("Everlaw/Sharing/ShareableObject");
import { addToastWrapper } from "Everlaw/ToastBoxManager";

type NotificationHandlerParams = {
    sender: string;
    newCount: number;
    messageThread: Message.MessageThread;
};

type MessagePageNotificationHandler = (messageThread: Message.MessageThread) => void;
let messagePageNotificationHandler: MessagePageNotificationHandler = () => {};

export function setNotificationHandler(handler: MessagePageNotificationHandler) {
    messagePageNotificationHandler = handler;
}

export function notificationHandler(notification: NotificationHandlerParams): void {
    const onMessagesPage = window.location.pathname.indexOf("messages.do") > 0;
    if (notification.messageThread) {
        const messageThread = Base.set(Message.MessageThread, notification.messageThread);
        const msg = messageThread.getMostRecentMessage();
        if (messageThread.getMostRecentMessage().sentByMe()) {
            return;
        } else if (onMessagesPage) {
            messagePageNotificationHandler(messageThread);
            // don't show notification if we're on the messages page
            return;
        }
        let body: Dom.Content = messageThread.getSubject();
        if (msg.attachmentClass) {
            const classInfo = ShareableObject.getClassInfo(msg.attachmentClass);
            body = [body, Dom.br(), "Attachment: "];
            if (msg.attachmentObject) {
                const attachLink = Dom.a(
                    {
                        target: "_blank",
                        href: classInfo.href(
                            msg.attachmentId,
                            msg.attachmentObject,
                            notification.messageThread.id,
                        ),
                    },
                    msg.attachmentObject.display(),
                );
                body = body.concat([attachLink]);
            } else {
                body = body.concat([classInfo.displayName, " [DELETED]"]);
            }
        }
        addToastWrapper({
            title: "New message from " + notification.sender,
            icon: "Mail",
            body: body,
        });
    }

    // don't add to the notification count if we're on the messages page
    !onMessagesPage && Message.setNewMessagesCount(notification.newCount);
}
