import Base = require("Everlaw/Base");
import { Custodian } from "Everlaw/LegalHold/Custodian";
import { Arr, Compare as Cmp, Constants as C, Is } from "core";
import Dataset = require("Everlaw/Model/Processing/ProcessingDataset");
import { DirectoryTar } from "Everlaw/DirectoryTar";
import Dom = require("Everlaw/Dom");
import FeatureStatus = require("Everlaw/FeatureStatus");
import FilePicker = require("Everlaw/FilePicker");
import Files = require("Everlaw/Files");
import Input = require("Everlaw/Input");
import { creditSettings } from "Everlaw/LLM/LLMFrontendElements";
import { ProcessingDocument } from "Everlaw/Model/Processing/ProcessingDocument";
import Project = require("Everlaw/Project");
import { DatasetSource, DatasetSourceRestData } from "Everlaw/Model/Processing/DatasetSource";
import { ProcessedSource } from "Everlaw/Model/Processing/ProcessedSource";
import { Recommendations } from "Everlaw/SmartOnboarding/RecommendationConstants";
import { IconButton } from "Everlaw/UI/Button";
import Rest = require("Everlaw/Rest");
import Task = require("Everlaw/Task");
import ComboBox = require("Everlaw/UI/ComboBox");
import * as LabeledIcon from "Everlaw/UI/LabeledIcon";
import Icon = require("Everlaw/UI/Icon");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Tooltip = require("Everlaw/UI/Tooltip");
import UploadUI = require("Everlaw/UI/Upload/Util/UploadUI");
import UploadSource = require("Everlaw/Model/Upload/UploadSource");
import { SourceState } from "Everlaw/Model/Upload/S3Source";
import User = require("Everlaw/User");
import Util = require("Everlaw/Util");
import Zip = require("Everlaw/Zip");
import { GlobalObject, ObjJson } from "Everlaw/Base";
import { BigCardObject } from "Everlaw/BigCard";
import { Poller } from "Everlaw/Model/Processing/Poller";
import { OngoingJobInfo, OngoingJobInfoJson } from "Everlaw/Model/Processing/OngoingJobInfo";
import { PasswordWidget } from "Everlaw/Model/Processing/ProcessingConfigWidgets";
import { CustodianMapping, ProcessingConfig } from "Everlaw/Model/Processing/ProcessingDataset";
import { addToast } from "Everlaw/ToastBoxManager";
import { ToastType } from "design-system";
import dojo_on = require("dojo/on");
import dojo_topic = require("dojo/topic");

export interface DatasetStats {
    nativeSize: number;
    deduped: number;
    dedupedSize: number;
    nist: number;
    nistSize: number;
    pages: number;
    billableSize: number;
    missingPassword: number;
    containerErrors: number;
    allIssues: number; // Count of docs with examine, pdf, text errors and/or missing passwords
    ocrPages: number;
    malicious: number;
    filetypes: { [filetype: string]: number };
    missingMime: number;
}

export interface ProcessingOptions {
    replaceMetadata: boolean;
    forceOcr: boolean;
    flattenOcr: boolean;
    forceRerun: boolean;
    pageNoteOverride: boolean;
    startStage: string;
}

export interface ProcessingStatus {
    uploading: DatasetSource[];
    running: Job[];
    isPreparing: boolean;
    isTransferring: boolean;
    // True if all files in this processing job have been uploaded to S3.
    transferringFinished: boolean;
}

export interface ProcessingDetails {
    stats: DatasetStats;
    docs: ProcessingDocument[];
    numProcessing: number;
}

abstract class WorkQueueJob extends Base.GlobalObject {
    override id: number;
    etaInfo: OngoingJobInfo;
    running = false;
    loadTimeout = null;

    constructor(params: { etaInfo: OngoingJobInfoJson }) {
        super(params);
        this._mixin(params);
    }

    progress(): string {
        return this.running ? this.etaInfo.display() : "...";
    }

    hasQueuedWork(queue: string): boolean {
        return this.etaInfo.hasQueuedWork(queue);
    }

    override _mixin(params: { etaInfo: OngoingJobInfoJson }) {
        const etaInfoJson = params.etaInfo;
        delete params.etaInfo;
        Object.assign(this, params);
        this.running = !!etaInfoJson;
        if (etaInfoJson) {
            this.etaInfo = new OngoingJobInfo(etaInfoJson);
        }
    }
}

export class Job extends WorkQueueJob implements BigCardObject {
    get className() {
        return "ProcessingJob";
    }
    name: string;
    datasetId: number;
    databaseId: number;
    config: ProcessingConfig;
    options: ProcessingOptions;
    userId: User.Id;
    timestamp: number;
    forceRerun: boolean;
    total: number;
    pdfErrors: number;
    textErrors: number;

    constructor(params: any) {
        super(params);
    }

    override display(): string {
        return `#${this.id} - ${this.name}`;
    }

    cancel(): void {
        Rest.post("processing/cancel.rest", { jobId: this.id }).then(() => {
            this.running = false;
            Base.publish(this);
        });
    }

    override compare(other: Job): 0 | 1 | -1 {
        return Cmp.num(other.timestamp, this.timestamp) || Cmp.num(this.id, other.id);
    }

    getCreated(): number {
        return this.timestamp;
    }

    getUserId(): User.Id {
        return this.userId;
    }

    status(): string {
        if (this.running) {
            return "PROCESSING";
        }
        if (this.hasQueuedWork("MAIN")) {
            return "QUEUED";
        }
        // There is only OCR/slow queue work queued.
        return "QUEUED (OCR)";
    }
}

type ProcessingGetData = {
    jobs: (ObjJson & { id: number })[];
    sources: DatasetSourceRestData[];
    datasets: ObjJson[];
};

const handleProcessingJobs = (fetchedData: unknown) => {
    const data = fetchedData as ProcessingGetData;
    const fetchedJobs = new Set<number>();
    if (data.jobs) {
        data.jobs.forEach((j) => fetchedJobs.add(j.id));
        Base.set(Job, data.jobs);
    }
    // Jobs we didn't get info on are no longer running.
    const finished = Base.get(Job)
        .filter((j) => j.running)
        .filter((j) => !fetchedJobs.has(j.id));
    finished.forEach((j) => (j.running = false));
    Base.publish(finished);
    if (data.sources) {
        // Mark sources that we didn't get info on (and which we don't already know are complete)
        // as being complete.
        const sourceIds: { [id: string]: boolean } = {};
        data.sources.forEach((ds) => (sourceIds[ds.id] = true));
        const uploaded = Base.get(DatasetSource).filter(
            (ds) =>
                !(ds.id in sourceIds)
                && ds.state !== SourceState.COMPLETED
                && ds.state !== SourceState.WILL_NOT_COMPLETE
                && ds.state !== SourceState.ERROR,
        );
        uploaded.forEach((ds) => (ds.state = SourceState.COMPLETED));
        Base.publish(uploaded);
        Base.set(DatasetSource, data.sources as ObjJson[]);
    }
    if (data.datasets) {
        Base.set(Dataset, data.datasets);
    }
    return (Base.get(Job).some((j) => j.running) ? 5 : 30) * C.SEC;
};

type FinishedProcessingJobsData = {
    job?: ObjJson & { running?: boolean };
    sourceIds: number[];
    dataset?: ObjJson;
};

export const finishProcessingJobs = (data: FinishedProcessingJobsData) => {
    if (data.job) {
        // Set to make sure in the front end that the job is actually done
        data.job.running = false;
        Base.set(Job, data.job);
    }
    if (data.sourceIds) {
        const sources = data.sourceIds
            .map((sid: number) => Base.get(DatasetSource, sid))
            .filter(
                (s: DatasetSource) =>
                    !!s
                    && s.state !== SourceState.COMPLETED
                    && s.state !== SourceState.WILL_NOT_COMPLETE
                    && s.state !== SourceState.ERROR,
            );
        sources.forEach((s: DatasetSource) => (s.state = SourceState.COMPLETED));
        Base.publish(sources);
    }
    if (data.dataset) {
        Base.set(Dataset, data.dataset);
        dojo_topic.publish("finished-processing-job", { datasetId: data.dataset.id });
    }
};

/**
 * Fetches the currently running jobs. Assumes that the datasets corresponding to each job has
 * already been set (via Base.set).
 */
export const JOB_POLLER = new Poller("processing/get.rest", handleProcessingJobs, () => {});

type AlternateFormatProcessingGetData = {
    jobs: (ObjJson & { id: number; parcel: Parcel })[];
};

export abstract class AlternateFormatJob extends WorkQueueJob {
    processingJobId: number;
}

export class SpreadsheetConversionJob extends AlternateFormatJob {
    get className(): string {
        return "SpreadsheetConversionJob";
    }
}

export class ChatArtifactStorageJob extends AlternateFormatJob {
    get className(): string {
        return "ChatArtifactStorageJob";
    }
}

export const SPREADSHEET_CONVERSION_JOB_POLLER = new Poller(
    "spreadsheet/getJobs.rest",
    createDataHandlerFor(SpreadsheetConversionJob),
);

export const CHAT_ARTIFACT_STORAGE_JOB_POLLER = new Poller(
    "chatViewer/getStorageJobs.rest",
    createDataHandlerFor(ChatArtifactStorageJob),
);

function createDataHandlerFor(
    alternateFormatJob: Base.Class<AlternateFormatJob>,
): (data: unknown) => number {
    return (fetchedData: unknown) => {
        const fetchedJobs = new Set<string>();
        if (fetchedData) {
            const data = fetchedData as AlternateFormatProcessingGetData;
            data.jobs.forEach((j) => {
                const key = GlobalObject.key(j.id, j.parcel);
                fetchedJobs.add(key);
            });
            Base.set(alternateFormatJob, data.jobs);
        }
        const finished = Base.get(alternateFormatJob)
            .filter((j) => j.running)
            .filter((j) => !fetchedJobs.has(j.getKey()));
        finished.forEach((j) => (j.running = false));
        Base.publish(finished);
        return (Base.get(ChatArtifactStorageJob).some((j) => j.running) ? 5 : 30) * C.SEC;
    };
}

/**
 * Fetches the File Transfers with upload counts. This function contains things that should be
 * loaded once, on load of the data.do page.
 */
export function loadOnce() {
    Rest.get("processing/getInitialRequest.rest").then(
        (data: any) => {
            if (data.processedSources) {
                Base.set(ProcessedSource, data.processedSources);
            }
        },
        () => {},
    );
}

export function fetchProcessingDetails(
    datasetId: number,
    jobId?: number,
): Promise<ProcessingDetails> {
    return Rest.get("processing/dataset-details.rest", { datasetId, jobId });
}

export function getProcessingStatus(dataset: Dataset): ProcessingStatus {
    const running = jobsForDataset(dataset).filter((j) => j.running);
    const sources = Base.get(DatasetSource).filter((s) => s.datasetId === dataset.id);
    const uploading = sources.filter(
        (s) =>
            s.isQueuedOrOngoing()
            || (s.uploading()
                && !s.localStatus
                && s.lastActive
                && Date.now() - s.lastActive.timestamp < 5 * C.MIN),
    );
    const preparing = sources.some((s) => s.state === SourceState.PROCESSING);
    return {
        uploading: uploading,
        running: running,
        isPreparing: preparing,
        isTransferring: uploading.length > 0 || preparing,
        transferringFinished: sources.filter((s) => s.uploading()).length === 0,
    };
}

export function hasActivity(dataset: Dataset): boolean {
    const { uploading, running, isPreparing, isTransferring } = getProcessingStatus(dataset);
    return isPreparing || isTransferring || running.length > 0 || uploading.length > 0;
}

export function promptRerun(dataset: Dataset): void {
    if (FeatureStatus.isReprocessingDown()) {
        FeatureStatus.showReprocessingDisabledDialog();
        return;
    }
    QueryDialog.create({
        title: "Re-run dataset " + dataset.name,
        prompt: `Re-run all documents with errors in dataset ${dataset.name}?`,
        onSubmit: () => {
            Task.createTask("tasks/processDataset.rest", {
                dataset: dataset.id,
            });
            setTimeout(() => {
                JOB_POLLER.startPolling();
            }, 2000);
            return true;
        },
    });
}

export function jobsForDataset(ds: Dataset) {
    return Base.get(Job).filter((j) => j.datasetId === ds.id);
}

export function completionHandler(res: { type: string; data: string }) {
    const jobType = res.type === "PROCESS" ? "Processing Job" : "Production";
    if (res.type === "PROCESS") {
        Recommendations.CREATE_PRODUCTION_PROTOCOL.trigger(true);
    }
    addToast({ title: `${jobType} complete`, children: res.data, type: ToastType.SUCCESS });
}

export function updateCustodianSelectionDropdown(
    dropdown: ComboBox,
    displayedHoldCustodians: Custodian[],
): void {
    if (!dropdown) {
        return;
    }
    dropdown.headers = !!displayedHoldCustodians?.length;
    dropdown.hide(custodianSuggestions.holdNoticeCustodians);
    dropdown.show(custodianSuggestions.metadataCustodians);
    if (displayedHoldCustodians) {
        dropdown.show(displayedHoldCustodians);
        // Hide any metadata custodians that are already included as hold notice custodians.
        // Metadata custodians are just primitive wrapped strings, so just removing the Custodian
        // displays from the list works here, since our dropdowns filter the show and hide inputs by
        // class first (e.g. filter by class type first, then show/hide matching entries)
        dropdown.hide(Base.wrapPrimitives(displayedHoldCustodians.map((c) => c.display())));
    }
}

export let custodianSuggestions: {
    holdNoticeCustodians: Custodian[];
    metadataCustodians: Base.Primitive<string>[];
} = {
    holdNoticeCustodians: [],
    metadataCustodians: [],
};

export const HOLD_NOTICE_NAME_MAP = {
    Primitive: "Other custodians",
    Custodian: "From Legal Holds",
};

export const fetchCustodianSuggestions = Util.lazy(() => {
    return Rest.get("custodianSuggestions.rest", {
        dbId: Project.CURRENT.databaseId,
    }).then((data: { metadataCustodians: string[]; holdNoticeCustodians: Custodian[] }) => {
        custodianSuggestions = {
            holdNoticeCustodians: data.holdNoticeCustodians
                ? Base.set(Custodian, data.holdNoticeCustodians)
                : [],
            metadataCustodians: Base.wrapPrimitives(data.metadataCustodians),
        };
    });
});

export interface DefaultCustodianTableInterface {
    setDefault(val: string): void;
    destroy(): void;
}

export class CustodianTable implements DefaultCustodianTableInterface {
    node: HTMLDivElement;
    entries: FileRow[] = [];
    private readonly toDestroy: Util.Destroyable[] = [];
    private readonly autoFillButton: LabeledIcon;
    private readonly infoIcon: Icon;
    private defaultCustodian = "";
    // Make sure to fetch custodian suggestions before constructing this.
    constructor(files: FilePicker.FileWithId[]) {
        this.autoFillButton = new LabeledIcon("edit-blue-20", {
            label: this.getAutofillCustodiansText(0),
            onClick: () => {
                this.forEachEntry((fileRow) => {
                    // The first entry can have a placeholder value of the default custodian (if it exists)
                    fileRow.autoFillAllVisible(this.defaultCustodian);
                });
            },
            class: "custodian-buttons__autofill-all",
        });
        this.infoIcon = new Icon("info-circle-20 custodian-buttons__info-circle", {
            tooltip: Dom.div(this.getAutofillCustodiansTooltipText(0)),
            tooltipPosition: ["above-centered"],
        });
        const clearAllButton = Dom.a({ class: "custodian-buttons__clear-all" }, "Clear all");
        clearAllButton.addEventListener("click", () => {
            this.forEachEntry((fileRow) => {
                // Clear custodians and set placeholder to be the default custodian
                fileRow.clearCustodian(this.defaultCustodian);
            });
        });
        this.toDestroy.push(this.autoFillButton, this.infoIcon);
        let tableNode: HTMLElement;
        this.node = Dom.div(
            { class: "custodian-container" },
            Dom.div(
                { class: "custodian-buttons" },
                this.autoFillButton.getNode(),
                this.infoIcon.getNode(),
                Dom.div({ class: "custodian-buttons__separator" }),
                clearAllButton,
            ),
            (tableNode = Dom.div(
                { class: "custodian-table custodian-table-border" },
                Dom.div(
                    { class: "custodian-table-row custodian-header" },
                    Dom.div({ class: "custodian-table-left" }, "Documents"),
                    Dom.div({ class: "custodian-table-right" }, "Custodians"),
                ),
            )),
        );
        const updateCustodianCount = () => this.updateVisibleCustodianCount();
        Arr.sort(files, {
            key: (f) => f.name,
        }).forEach((file) => {
            let entry: FileRow;
            const uploadSource = file.source;
            if (uploadSource === UploadSource.LOCAL && FilePicker.looksLikeZip(file)) {
                entry = new ZipRow((<FilePicker.SingleFileWithId>file).file, updateCustodianCount);
            } else if (uploadSource === UploadSource.LOCAL_DIR) {
                entry = new DirectoryTarRow((<DirectoryTar>file).dirEntry, updateCustodianCount);
            } else if (uploadSource === UploadSource.VAULT && file.custodians) {
                // For vault exports with custodians, we show a special vault custodian editor.
                entry = new VaultRow(file, updateCustodianCount);
            } else {
                entry = new FileRow({
                    fullPath: file.name,
                    updateCustodianCount,
                    // Some vault files don't have any custodians, in which case we use a single
                    // FileRow as well.
                    icon:
                        uploadSource === UploadSource.VAULT
                            ? "google-vault-logo"
                            : file.isFolder
                              ? "folder"
                              : UploadUI.iconForFilename(file.name),
                    indent: 0,
                    hasTextbox: true,
                    // When promoting from staging area, check if a custodian has been set already.
                    preseededCustodian:
                        uploadSource === UploadSource.STAGING_AREA
                        || uploadSource === UploadSource.STAGING_AREA_DIR
                            ? (<FilePicker.StagingAreaPromotionFile>file).initialCustodian
                            : undefined,
                });
            }
            this.entries.push(Dom.place(entry, tableNode));
        });
        this.updateVisibleCustodianCount();
    }
    private updateVisibleCustodianCount(): void {
        // Get the count
        let count = 0;
        this.forEachEntry((f) => {
            count += f.countVisibleCustodians();
        });
        // Update the info tooltip and the autofill label to match the count
        this.autoFillButton.label.textContent = this.getAutofillCustodiansText(count);
        this.infoIcon.tooltip.setContent(this.getAutofillCustodiansTooltipText(count));
    }
    private getAutofillCustodiansText(count: number): string {
        return `Autofill all visible custodians (${count})`;
    }
    private getAutofillCustodiansTooltipText(count: number): string {
        return (
            `Autofill all custodians with the `
            + `${Util.countOf(count, "visible path/filename", "visible paths/filenames")}. `
            + "Custodians of files in collapsed folders will be assigned using the closest visible parent folder."
        );
    }
    setDefault(defaultCustodian: string): void {
        // Propagate placeholder info from the default box.
        this.defaultCustodian = defaultCustodian;
        this.entries.forEach((e) => {
            e.setPlaceholder(defaultCustodian);
        });
    }
    forEachEntry(func: (row: FileRow) => void): void {
        this.entries.forEach((row) => func(row));
    }
    custodianMapping(): CustodianMapping {
        const custodians: CustodianMapping = {};
        this.entries.forEach((entry) => {
            Object.entries(entry.custodianValues()).forEach(([fileName, val]) => {
                if (val) {
                    custodians[fileName] = val;
                }
            });
        });
        return custodians;
    }
    destroy(): void {
        Dom.destroy(this.node);
        Util.destroy(this.entries);
        Util.destroy(this.toDestroy);
    }

    getCustodiansList(defaultCustodian?: string): string[] {
        const hasRowMissingValue = this.entries.some(
            (row: FileRow) => row.textbox && !row.textbox.getValue(),
        );
        const custodianSet = new Set(Object.values(this.custodianMapping()));

        if (hasRowMissingValue && defaultCustodian) {
            custodianSet.add(defaultCustodian);
        }

        return Array.from(custodianSet).sort(Cmp.strCI);
    }
}

export function updateCustodiansAndPasswords(
    dataset: Dataset,
    getPrompt: (passwordBox: HTMLElement) => HTMLElement,
    getCustodianMapping: () => Dataset.CustodianMapping,
    submitCallback: () => void,
): void {
    const passwordWidget = new PasswordWidget();
    const passwordBox = Dom.div(
        { style: "padding-bottom: 14px" },
        Dom.div(
            {
                style: {
                    paddingBottom: "4px",
                },
            },
            "Passwords for protected files:",
        ),
        passwordWidget.getNode(),
    );
    const prompt: HTMLElement = getPrompt(passwordBox);
    QueryDialog.create({
        title: "Add passwords & custodians",
        prompt,
        onSubmit: () => {
            const custodianMapping = getCustodianMapping();
            const pws = passwordWidget.getValue();
            if (Object.keys(custodianMapping).length > 0 || pws.length > 0) {
                Rest.post("processing/addCustodians.rest", {
                    dataset: dataset.id,
                    custodians: JSON.stringify(custodianMapping),
                    passwords: pws,
                }).then(() => submitCallback && submitCallback());
            } else {
                submitCallback && submitCallback();
            }
            return true;
        },
    });
}

export function updateCustodiansAndPasswordsForFiles(
    files: FilePicker.FileWithId[],
    callback: (custodians: string[]) => void,
    dataset: Dataset,
): void {
    fetchCustodianSuggestions().then(() => {
        const table = new CustodianTable(files);
        const callbackWrapper = () => {
            const custodians = table.getCustodiansList(dataset.defaultCustodian);
            callback(custodians);
        };
        dataset.defaultCustodian && table.setDefault(dataset.defaultCustodian);
        updateCustodiansAndPasswords(
            dataset,
            (passwordBox) => Dom.div(passwordBox, table.node),
            () => table.custodianMapping(),
            callbackWrapper,
        );
    });
}

interface FileRowParams {
    fullPath: string;
    // Callback to parent table to update custodian counts.
    updateCustodianCount: () => void;
    // The path of the parent directory for this file - this is only used for display!
    // We'll strip off this directory (plus a '/' delimiter) from the given full path.
    parentDir?: string;
    // You can explicitly pass null if you don't want an icon (but most applications will want one).
    // However, the row will be shorter than normal if you do not provide one...
    icon: string;
    indent: number;
    hasTextbox: boolean;
    // If non-null, clicking on the row will expand and show these children.
    children?: () => Promise<FileRow[]>;
    // If true, the autofill textbox will be filled by default.
    fillNow?: boolean;
    // If provided, this class is applied to the filename span.
    nameClass?: string;
    // If set, the custodian table will default to this option.
    preseededCustodian?: string;
}

const EMPTY_CUSTODIAN_TEXT = "Select or enter custom value";

class FileRow {
    node: HTMLDivElement;
    textbox?: ComboBox;
    protected toDestroy: Util.Destroyable[] = [];
    private loading = false;
    private loaded = false;
    private readonly updateCustodianCount: () => void;
    private children: FileRow[] = [];
    private readonly fullPath: string;
    private cb: ComboBox;
    // The most current value we should display as a placeholder in our textbox.
    private placeholder: string;
    // The value used if the autofill feature is used.
    private readonly autoFillValue: string;

    private displayedHoldCustodians: Custodian[];

    constructor(params: FileRowParams) {
        this.fullPath = params.fullPath;
        this.updateCustodianCount = params.updateCustodianCount;
        this.node = Dom.div();
        const row = Dom.create("div", { class: "custodian-table-row" }, this.node);
        // Slice off the parent directory (and the joining "/")
        const display = params.parentDir
            ? params.fullPath.slice(params.parentDir.length + 1)
            : params.fullPath;
        const displaySpan = Dom.span(display);
        if (params.nameClass) {
            Dom.addClass(displaySpan, params.nameClass);
        }
        const content: Dom.Content[] = [];
        if (params.icon) {
            const icon = new Icon(params.icon);
            Dom.addClass(icon, "picker-icon");
            content.push(icon.node);
        }
        content.push(displaySpan);
        const left = Dom.create(
            "div",
            {
                class: "custodian-table-left",
                content,
            },
            row,
        );
        this.toDestroy.push(new Tooltip.MirrorTooltip(displaySpan));
        // There's some left-padding already on the cell, so don't overwrite it unless we want something
        // other than the default.
        if (params.indent > 0) {
            Dom.style(left, "paddingLeft", params.indent * 32 + "px");
        }
        const right = Dom.create("div", { class: "custodian-table-right" }, row);
        if (params.hasTextbox) {
            this.autoFillValue = basename(params.fullPath);
            this.textbox = this.makeTextBoxCell(right, params.fillNow, params.preseededCustodian);
            this.toDestroy.push(this.textbox);
        }
        if (params.children) {
            Dom.addClass(left, "action");
            const arrow = new Icon("caret-down-20 rotated");
            Dom.place(arrow, left, "first");
            this.toDestroy.push(
                dojo_on(left, Input.tap, () => {
                    if (!this.loading) {
                        if (!this.loaded) {
                            this.loading = true;
                            params.children?.().then((rows) => {
                                this.children = rows;
                                this.loading = false;
                                this.loaded = true;
                                Dom.place(rows, this.node);
                                Dom.removeClass(arrow, "rotated");
                                this.propagatePlaceholder();
                                this.updateHoldNotice(this.displayedHoldCustodians);
                                this.updateCustodianCount();
                            });
                        } else {
                            Dom.toggleClass(this.children, "hidden");
                            Dom.toggleClass(arrow, "rotated");
                            this.updateCustodianCount();
                        }
                    }
                }),
            );
        }
    }
    setPlaceholder(ph: string): void {
        this.textbox?.tb.setPlaceholder(ph || EMPTY_CUSTODIAN_TEXT);
        this.placeholder = ph;
        // Send a placeholder down to all our children
        this.propagatePlaceholder();
    }
    // Returns a map from custodian path to custodian value for this file and any children
    custodianValues(): { [fileName: string]: string } {
        const res: { [fileName: string]: string } = {};
        if (this.textbox?.getValue()) {
            res[this.fullPath] = this.textbox.getValue();
        }
        this.children.forEach((c) => {
            Object.entries(c.custodianValues()).forEach(([fileName, val]) => {
                res[this.relname(fileName)] = val;
            });
        });
        return res;
    }
    updateHoldNotice(displayedHoldCustodians: Custodian[]): void {
        this.displayedHoldCustodians = displayedHoldCustodians;
        updateCustodianSelectionDropdown(this.cb, displayedHoldCustodians);
        this.children.forEach((c) => c.updateHoldNotice(displayedHoldCustodians));
    }
    // Combine the child pathname with ours as appropriate to generate a full path.  By default we
    // assume the child has the full path.
    protected relname(childname: string): string {
        return childname;
    }
    destroy(): void {
        Util.destroy(this.toDestroy);
    }
    private propagatePlaceholder(): void {
        // The value we send down to our children to use a placeholder - we prefer our textbox value,
        // if there is one, otherwise we use the placeholder we've been given from above.
        let toPropagate = this.placeholder;
        if (this.textbox?.getValue()) {
            toPropagate = this.textbox.getValue();
        }
        this.children.forEach((c) => {
            c.setPlaceholder(toPropagate);
        });
    }

    /**
     * Uses the autofill value as the custodian if the row is visible on the table.
     */
    autoFillAllVisible(placeholderValue: string): void {
        // Properly set the placeholder value
        this.placeholder = placeholderValue;
        this.textbox?.tb.setPlaceholder(this.placeholder || EMPTY_CUSTODIAN_TEXT);
        // Check if the row is visible.
        if (!this.node.classList.contains("hidden")) {
            // Figure out which value to propagate.
            let toPropagate = placeholderValue;
            // If it has a custodian textbox, we set it correctly to the autofill value.
            if (this.textbox) {
                this.cb.setValue(this.autoFillValue);
                // Additionally know to propagate this new value.
                toPropagate = this.autoFillValue;
            }
            // Continue setting and propagating values to children.
            this.children.forEach((c) => {
                c.autoFillAllVisible(toPropagate);
            });
        } else {
            // If this row is not visible, then it follows that every other children is not visible.
            // In this case, we can default and simply use the propagatePlaceholder function to finish.
            this.propagatePlaceholder();
        }
    }

    /**
     * Clears all custodians
     */
    clearCustodian(defaultCustodian = ""): void {
        // Set placeholder value to the default custodian (or no default)
        this.placeholder = defaultCustodian;
        // Remove and correctly set values of the textbox.
        if (this.textbox) {
            this.cb.setValue("");
            // We set the placeholder value to be the default custodian if there is one
            this.textbox.tb.setPlaceholder(defaultCustodian || EMPTY_CUSTODIAN_TEXT);
        }
        // Clear values for children.
        this.children.forEach((c) => {
            c.clearCustodian(defaultCustodian);
        });
    }
    /**
     * Gets the count of visible custodians including on this row
     */
    countVisibleCustodians(): number {
        if (this.node.classList.contains("hidden")) {
            // This row is hidden, so there isn't anything visible to count.
            return 0;
        }
        // Counter of visible custodians
        let count = 0;
        if (this.textbox) {
            // If there is a textbox to fill in the custodian, then add 1 to the count
            count += 1;
        }
        for (const childRow of this.children) {
            count += childRow.countVisibleCustodians();
        }
        return count;
    }
    private makeTextBoxCell(
        node: HTMLElement,
        setNow = false,
        preseededCustodian?: string,
    ): ComboBox {
        const withHoldNoticeCustodians = !!custodianSuggestions.holdNoticeCustodians.length;
        let initialOption: Base.Primitive<string> | undefined = undefined;
        if (Is.defined(preseededCustodian)) {
            initialOption = new Base.Primitive(preseededCustodian);
            custodianSuggestions.metadataCustodians.push(initialOption);
        }
        this.cb = Dom.place(
            new ComboBox({
                placeholder: EMPTY_CUSTODIAN_TEXT,
                elements: [
                    custodianSuggestions.holdNoticeCustodians,
                    custodianSuggestions.metadataCustodians,
                ],
                nameMap: withHoldNoticeCustodians ? HOLD_NOTICE_NAME_MAP : {},
                headers: withHoldNoticeCustodians,
                popup: "after",
                focusOnTap: true,
                // Needed to propagate the custodian value from the mid level to the files in a zip
                onFilter: (val) => {
                    this.propagatePlaceholder();
                },
                textBoxParams: {
                    icon: new IconButton({
                        iconClass: "edit-20",
                        onClick: () => {
                            this.cb.setValue(this.autoFillValue);
                            this.propagatePlaceholder();
                        },
                        tooltip: "Autofill custodian with path/filename",
                        tooltipPosition: ["after", "above-centered"],
                    }),
                },
                onSelect: (elem) => {
                    if (elem instanceof Custodian) {
                        ga_event(
                            "Upload Natives custodians page",
                            "Hold notice custodian chosen from dropdown",
                            "Option selected from dropdown",
                        );
                    }
                },
                initialSelected: initialOption,
            }),
            node,
        );
        this.cb.onChange = () => {
            // This will propagate the change downwards.
            this.propagatePlaceholder();
            this.cb.blur(); // close the dropdown
        };
        if (setNow) {
            this.cb.setValue(this.autoFillValue);
        }
        return this.cb;
    }
}

function splitFilename(filename: string): string[] {
    // Split on both slashes (zip entries sometimes have windows-style filenames)
    return filename.split(/[\/\\]/);
}

function basename(filename: string): string {
    const spl = splitFilename(filename);
    return spl[spl.length - 1];
}

interface DirEnt {
    path: string;
    dirs: DirEnt[];
    files: string[];
}

function simpleFileRow(
    parentDir: string,
    fullPath: string,
    indent: number,
    updateCustodianCount: () => void,
    hasTextbox = false,
): FileRow {
    return new FileRow({
        parentDir,
        updateCustodianCount,
        fullPath,
        indent: indent,
        icon: UploadUI.iconForFilename(fullPath),
        hasTextbox,
    });
}

interface ContainerEntry {
    filename: string;
    isDir: boolean;
}

/**
 * Convert a list of container entries (files/directories) into child rows.
 */
function loadChildren(filenames: ContainerEntry[], updateCustodianCount: () => void): FileRow[] {
    const dirs: { [dirname: string]: DirEnt } = {};
    dirs[""] = { dirs: [], files: [], path: "" };
    filenames.forEach((ze) => {
        const nameSplit = splitFilename(ze.filename);
        let curr = "";
        // Walk down the path tree for this file/directory
        nameSplit.forEach((part, idx) => {
            // Skip empty parts
            if (part) {
                // Our current location is a directory unless we're at the end and the entire entry
                // is for a directory.
                const isDir = idx !== nameSplit.length - 1 || ze.isDir;
                const path = curr ? curr + "/" + part : part;
                if (isDir) {
                    if (!(path in dirs)) {
                        // We haven't seen this directory before.  Add it to our set and to its parent.
                        dirs[path] = { dirs: [], files: [], path };
                        dirs[curr].dirs.push(dirs[path]);
                    }
                } else {
                    // Add this as a file in the current directory
                    dirs[curr].files.push(path);
                }
                curr = path;
            }
        });
    });
    function genRows(dir: string, depth: number): FileRow[] {
        const rows: FileRow[] = [];
        const root = dirs[dir];
        root.dirs.forEach((de) => {
            rows.push(
                new FileRow({
                    parentDir: dir,
                    updateCustodianCount,
                    fullPath: de.path,
                    indent: depth,
                    icon: "folder",
                    hasTextbox: true,
                    children:
                        de.path in dirs ? () => Promise.resolve(genRows(de.path, depth + 1)) : null,
                }),
            );
        });
        const looseRow = looseFilesRow(dir, depth, root.files, updateCustodianCount);
        if (looseRow) {
            rows.push(looseRow);
        }
        if (rows.length === 0) {
            rows.push(emptyDirectoryRow(depth, updateCustodianCount));
        }
        return rows;
    }
    return genRows("", 1);
}

function emptyDirectoryRow(depth: number, updateCustodianCount: () => void): FileRow {
    return new FileRow({
        fullPath: "This folder is empty.",
        updateCustodianCount,
        indent: depth,
        icon: null,
        hasTextbox: false,
        nameClass: "italic",
    });
}

/**
 * Create a row that represents a collection of loose files.
 * If there's a single filename, this will be a simple FileRow; if there is more than one, it'll be
 * a row that lists how many files there are and can be expanded to show all the actual filenames
 * nested under it.
 * If there are no filenames, this returns null.
 */
function looseFilesRow(
    parentDir: string,
    depth: number,
    looseFiles: string[],
    updateCustodianCount: () => void,
): FileRow {
    if (looseFiles.length === 1) {
        return simpleFileRow(parentDir, looseFiles[0], depth, updateCustodianCount, true);
    } else if (looseFiles.length > 1) {
        return new FileRow({
            fullPath: Util.countOf(looseFiles.length, "File"),
            updateCustodianCount,
            indent: depth,
            icon: "files",
            hasTextbox: false,
            children: () => {
                return Promise.resolve(
                    looseFiles.map((f) => {
                        return simpleFileRow(parentDir, f, depth + 1, updateCustodianCount, true);
                    }),
                );
            },
        });
    } else {
        return null;
    }
}

class ZipRow extends FileRow {
    constructor(
        private file: File,
        updateCustodianCount: () => void,
    ) {
        super({
            fullPath: file.name,
            updateCustodianCount,
            icon: "file-zip",
            indent: 0,
            hasTextbox: true,
            children: async (): Promise<FileRow[]> => {
                const zipEntries = await Zip.tryLoadEntries(file);
                return loadChildren(zipEntries, updateCustodianCount);
            },
        });
    }
    // We need to join the child path with our zip file path.
    protected override relname(child: string) {
        return this.file.name + "//" + child;
    }
}

class VaultRow extends FileRow {
    // A row type for mapping Google Vault custodians by path.
    constructor(
        private file: FilePicker.FileWithId,
        updateCustodianCount: () => void,
    ) {
        super({
            fullPath: file.name,
            updateCustodianCount,
            icon: "google-vault-logo",
            indent: 0,
            // Since we have custodians, the top level file doesn't need its own textbox.
            hasTextbox: false,
            children: () => {
                // Make a row for each custodian.
                return Promise.resolve(
                    Arr.sort(file.custodians, { cmp: Cmp.strCI }).map((e) => {
                        return new FileRow({
                            fullPath: e,
                            updateCustodianCount,
                            indent: 1,
                            icon: "user",
                            hasTextbox: true,
                            children: null,
                            fillNow: true,
                        });
                    }),
                );
            },
        });
    }
    protected override relname(child: string) {
        // Our processing system will extract the vault files under these custodian paths (e.g. a
        // file with custodian foobar will be at "x.vault//foobar/{filename}"
        return this.file.name + "//" + child;
    }
}

/**
 * A FileRow entry based on a Files.DirectoryReader.
 * We read the directory entries on demand, building nested DirectoryRow (or simple FileRow) entries
 * for the directories and files we find.
 */
class DirectoryRow extends FileRow {
    constructor(
        protected directoryEntry: Files.DirectoryEntry,
        parentDir: string,
        depth: number,
        updateCustodianCount: () => void,
        fullPath = directoryEntry.fullPath,
    ) {
        super({
            fullPath,
            updateCustodianCount,
            parentDir,
            icon: "folder",
            indent: depth,
            hasTextbox: true,
            children: () => {
                return this.loadDirectory().then((res: Files.Entry[]) => {
                    const files: Files.FileEntry[] = [];
                    const rows: FileRow[] = [];
                    res.forEach((entry) => {
                        if (entry.isFile) {
                            files.push(<Files.FileEntry>entry);
                        } else {
                            rows.push(
                                new DirectoryRow(
                                    <Files.DirectoryEntry>entry,
                                    directoryEntry.fullPath,
                                    depth + 1,
                                    updateCustodianCount,
                                ),
                            );
                        }
                    });
                    const looseRow = looseFilesRow(
                        this.directoryEntry.fullPath,
                        depth + 1,
                        files.map((f) => f.fullPath),
                        updateCustodianCount,
                    );
                    if (looseRow) {
                        rows.push(looseRow);
                    }
                    if (rows.length === 0) {
                        // If there are no contents, make sure to add an "empty directory" indicator,
                        // otherwise nothing happens when you try and expand it.
                        rows.push(emptyDirectoryRow(depth + 1, updateCustodianCount));
                    }
                    return rows;
                });
            },
        });
    }
    private loadDirectory(): Promise<Files.Entry[]> {
        const reader = this.directoryEntry.createReader();
        const res: Files.Entry[] = [];
        return new Promise<Files.Entry[]>((resolve, reject) => {
            const readEntries = () => {
                reader.readEntries((entries) => {
                    if (entries.length === 0) {
                        resolve(res);
                    } else {
                        entries.forEach((e) => res.push(e));
                        readEntries();
                    }
                }, reject);
            };
            readEntries();
        });
    }
}

class DirectoryTarRow extends DirectoryRow {
    constructor(directoryEntry: Files.DirectoryEntry, updateCustodianCount: () => void) {
        // We want to use the top-level directory name here (which will be just the folder name, no
        // slashes, etc).
        super(directoryEntry, "", 0, updateCustodianCount, directoryEntry.name);
    }
    protected override relname(child: string) {
        // We need to convert a child's full path into the correct path we'll get from processing.
        // This should be our directory entry's name, a double slash, and then the relative path
        // of the child under this directory.
        // The child paths we have include this top directory's full path, so first we have to slice
        // it off (and the separating slash).
        const withoutTopDir = child.slice(this.directoryEntry.fullPath.length + 1);
        return this.directoryEntry.name + "//" + withoutTopDir;
    }
}
