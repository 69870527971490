import Button = require("Everlaw/UI/Button");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import { Is } from "core";
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Util = require("Everlaw/Util");
import Win = require("Everlaw/Win");
import { CreateRequestDemoForm } from "Everlaw/MarketoForm";
import { wrapReactComponent } from "Everlaw/UI/ReactWidget";

/*
 * These elements are in this file together to prevent circular dependencies, stemming from
 * SizzleReelDialogs being created by Banners, while also containing a Banner.
 */

interface BannerLink {
    /** The text of the link, defaults to learn more. */
    text?: string;
    /** The link url. */
    url?: string;
    /** If defined, opens the request a demo modal. */
    openRequestDemoModal?: boolean;
}

type BannerContent = (Dom.Content | BannerLink)[];

interface BannerParams {
    /** Label content for the banner, consisting of content and links. */
    content: BannerContent;
    /** Whether or not the content should be bold, defaults to false. */
    boldContent?: boolean;
    /** If defined, creates a button to the far right of the banner with this label. */
    buttonLabel?: Dom.Content;
    /** If defined, opens a new tab when the optional button is clicked. */
    buttonLink?: string;
    /** Left and right margin, defaults to 16. */
    sideMargin?: number;
    /** The font size of the label and linkText, defaults to 14. */
    fontSize?: number;
    /** A callback function for when the link is clicked. */
    onLinkClick: () => void;
}

function isBannerLink(element: Dom.Content | BannerLink): element is BannerLink {
    const link = element as BannerLink;
    return [link.url || link.openRequestDemoModal].some(Is.defined);
}

class Banner {
    private node: HTMLElement;
    private toDestroy: Util.Destroyable[] = [];
    private onLinkOpen: () => void = () => {};
    constructor(params: BannerParams) {
        const content = this.createContent(params);
        this.node = Dom.create("div", {
            class: "sb-free-limited-feature-banner",
            content,
        });
        if (params.buttonLabel) {
            const buttonDiv = Dom.create("div", { class: "button-div" }, this.node);
            this.toDestroy.push(
                new Button({
                    label: params.buttonLabel,
                    onClick: () => params.buttonLink && !!Win.openExternal(params.buttonLink),
                    parent: buttonDiv,
                    class: "sb-free",
                    width: null,
                }),
            );
        }
        this.onLinkOpen = params.onLinkClick;
    }
    private createContent(params: BannerParams) {
        if (!Is.defined(params.content)) {
            return null;
        }
        const content = Dom.create("div", {
            class: params.boldContent ? "semi-bold" : "",
            content: this.getLabelElements(params.content),
        });
        const callout = Icon.callout("crown-filled-yellow", content, false);
        return callout;
    }
    private getLabelElements(content: BannerContent) {
        return content.map((element) =>
            isBannerLink(element) ? this.createLinkElement(element) : element,
        );
    }
    private createLinkElement(element: BannerLink) {
        const link = Dom.a({ class: "help-link" }, "Request a demo");

        if (element.openRequestDemoModal) {
            const requestDemoModalComponent = wrapReactComponent(CreateRequestDemoForm, {
                isButton: false,
                isModalVisible: false,
                onModalClose: () => {
                    requestDemoModalComponent.updateProps({ isModalVisible: false });
                },
            });
            link.onclick = () => {
                requestDemoModalComponent.updateProps({ isModalVisible: true });
                this.onLinkOpen();
            };
            this.toDestroy.push(requestDemoModalComponent);
        } else {
            link.href = element.url;
            link.rel = "noopener noreferrer";
            link.target = "_blank";
        }
        return link;
    }
    getNode() {
        return this.node;
    }
    destroy() {
        Util.destroy(this.toDestroy);
    }
}

interface BanneredDialogParams {
    dialogParams: QueryDialog.Params;
    bannerParams: BannerParams;
    bannerArea: "prompt" | "body";
}

/**
 * A QueryDialog with a SbFreeLimitedFeatureBanner as the QueryDialog prompt or body.
 */
export class BanneredDialog extends QueryDialog {
    constructor(params: BanneredDialogParams) {
        const classes = params.dialogParams.classes;
        params.dialogParams.classes = `sb-free-bannered-dialog ${classes ? " " + classes : ""}`;
        const banner = new Banner(params.bannerParams).getNode();
        if (params.bannerArea === "prompt") {
            params.dialogParams.prompt = banner;
        } else {
            params.dialogParams.body = banner;
        }
        super(params.dialogParams);
    }
}
