/*
 * This file contains functions and consolidated alerts for feature inaccessibility. In the event a
 * feature is down, we should consult the set of available server property variables to toggle on,
 * those currently being:
 * - AddingDocumentsDown: Whether or not creating new Document entries is down.
 *
 * In the event a downed feature is not exclusively covered by an existing property variable, we
 * should deploy a frontend hotfix with a function placeholder denoting the downed feature returning
 * true. Then, presumably with the backend hotfix, we should add a server property variable and
 * entry in JSP_PARAMS#FeaturesDown, to be checked in the function placeholder.
 */

import Dialog = require("Everlaw/UI/Dialog");

export function isAddingDocumentsToProjectDown() {
    return isAddingDocumentsDown();
}

export function isReprocessingDown() {
    return isAddingDocumentsDown();
}

export function arePartialProjectConversionsDown() {
    return isAddingDocumentsDown();
}

export function areProductionsDown() {
    return isAddingDocumentsDown();
}

export function isProjectCreationDown() {
    return isAddingDocumentsDown();
}

export function areUnitizationsDown() {
    return isAddingDocumentsDown();
}

export function areUploadsDown() {
    return isAddingDocumentsDown();
}

/**
 * Whether or not creating new Document entries is down, set by the "AddingDocumentsDown=true"
 * server property.
 */
function isAddingDocumentsDown() {
    return JSP_PARAMS.FeaturesDown.AddingDocuments;
}

export function showAddingDocumentsToProjectDisabledDialog() {
    showDisabledDialog("add documents to a project");
}

export function showReprocessingDisabledDialog() {
    showDisabledDialog("reprocess documents");
}

export function showPartialProjectConversionsDisabledDialog() {
    showDisabledDialog("convert a partial project to complete");
}

export function showProductionsDisabledDialog() {
    showDisabledDialog(getProductionsDisabledText());
}

export function showProjectCreationDisabledDialog() {
    showDisabledDialog("create projects");
}

export function showUnitizationsDisabledDialog() {
    showDisabledDialog("unitize a document");
}

export function showUploadsDisabledDialog() {
    showDisabledDialog("upload documents");
}

function showDisabledDialog(action: string) {
    Dialog.ok("System Error", getDisabledText(action));
}

export function getProductionsDisabledText() {
    return getDisabledText("generate productions");
}

export function getDisabledText(action: string) {
    const restriction = `Due to ongoing site maintenance, it is not currently possible to ${action}.`;
    const apology =
        "We are working on resolving this issue as quickly as possible and apologize for any inconvenience.";
    return `${restriction} ${apology}`;
}
