import BasicRadio = require("Everlaw/UI/BasicRadio");
import Checkbox = require("Everlaw/UI/Checkbox");
import Dom = require("Everlaw/Dom");
import ProdAdmin = require("Everlaw/Context/ProdAdmin");
import CxAdmin = require("Everlaw/Context/CxAdmin");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");
import { SystemPermission } from "Everlaw/SystemPermission";
import ValidatedSubmit = require("Everlaw/UI/ValidatedSubmit");
import User = require("Everlaw/User");

enum SuperuserPageReason {
    ACCESS_CLIENT_PROJECT = "Direct access to a project containing client case materials",
    IMPERSONATE_CLIENT = "Impersonate a client on their project",
    IMPERSONATE_FOR_DEMOS = "Impersonate for demos",
    ACCESS_PROCESSED_UPLOADS = "Access processed uploads",
    CHECK_WORKERS = "Check on threading, corpus, prediction, or clustering servers",
    CONFIRM_DELETION_REQUEST = "Confirm deletion request",
    REVIEW_ACTIVE_TASKS = "Review active tasks",
    REVIEW_COMPLETED_TASKS = "Review recently completed tasks",
    REVIEW_USER_ACTIVITY = "Review current user activity",
    REVIEW_USER_ACCESS_HISTORY = "Review user access history",
    REVIEW_BILLING_INFO = "Review billing information",
    ACCESS_SERVER_MANAGEMENT = "Access server management",
    QA_TESTING = "QA testing",
}

// a dialog that collects a user's reasons for accessing superuser.do
class SuperuserAccessDialog {
    onCheckboxChange: () => void;

    // fromIcon is true if this dialog was shown by clicking the 'S' diamond icon.
    constructor(private fromIcon: boolean) {
        const content = Dom.div(
            { class: "v-spaced-8" },
            Dom.div(
                Dom.span({ class: "h7" }, "Select your reason(s) for visiting the Superuser page"),
            ),
        );
        const checkboxes: Checkbox[] = Object.keys(SuperuserPageReason).map((r) => {
            return new Checkbox({
                label: SuperuserPageReason[r],
                parent: content,
                blockDisplay: true,
                onChange: () => {
                    this.onCheckboxChange && this.onCheckboxChange();
                },
            });
        });
        const registerOnChange = (onChange: () => void) => {
            this.onCheckboxChange = onChange;
        };

        const validatedCheckboxes = new CheckboxMultiSelect(checkboxes, registerOnChange);
        const dialog = QueryDialog.create({
            title: "Select reason for visiting Superuser page",
            prompt: "",
            onSubmit: () => {
                const selectedReasons = checkboxes
                    .filter((cb) => cb.isSet())
                    .map((cb) => cb.labelNode.textContent);
                Rest.post("/recordSuperuserPageAccess.rest", {
                    reasons: selectedReasons,
                }).then(
                    () => {
                        // When this dialog is shown, we cancel the normal behavior of redirecting
                        // to superuser.do, so redirect after the dialog is submitted.
                        if (this.fromIcon) {
                            window.location.assign("/superuser.do");
                        }
                    },
                    () => {
                        // If there is an error, redirect back to the admin.do page. If they used the
                        // icon, they can stay on whatever page they are already on.
                        if (!this.fromIcon) {
                            window.location.assign("/admin.do");
                        }
                    },
                );
                return true;
            },
            onCancel: () => {
                if (!this.fromIcon) {
                    window.location.assign("/admin.do");
                }
                return true;
            },
            submitText: "Done",
            body: content,
            forms: [validatedCheckboxes],
        });
        dialog.registerDestroyable(checkboxes);
    }
}

// a widget that ensures that at least one of its checkboxes are checked
class CheckboxMultiSelect implements ValidatedSubmit.ValidatedSubmitForm {
    constructor(
        public checkboxes: Checkbox[],
        public registerOnChange: (onChange: () => void) => void,
    ) {}

    isValid() {
        return this.checkboxes.some((cb) => cb.isSet());
    }

    focus() {
        this.checkboxes[0].focus();
    }

    subscribeToChanges(subscription: () => void) {
        this.registerOnChange(subscription);
    }

    addToSubmit(submitLogic: (e?) => void) {}
}

export function approveSUPage(fromIcon: boolean): SuperuserAccessDialog {
    return new SuperuserAccessDialog(fromIcon);
}

const ProjectPageReason = {
    CLIENT: {
        id: SuperuserPageReason.ACCESS_CLIENT_PROJECT,
        subcontent: "If you access this project, your access will be logged",
    },
    NON_CLIENT: {
        id: "Access to a non-client project for new hire onboarding/other reasons",
        subcontent: "Your access will not be logged",
    },
};

// This method is called when an everlaw admin clicks on a project/database/org that they're not a user of
export function approveProjectPage(
    onApprove?: () => void,
    onCancel?: () => void,
    entityName?: string,
): QueryDialog {
    entityName = entityName || "project";
    if (
        ProdAdmin.inContext()
        || CxAdmin.inContext()
        || User.me.hasEngAdminRole()
        || User.me.hasFinAdminRole()
    ) {
        // it is possible that PROD_ADMIN can't see the client data access tab
        // based on their roles, in which case we just tell them they can't access
        // client projects.
        return User.me.has(SystemPermission.VIEW_CLIENT_DATA_ACCESS_REQUESTS)
            ? redirectToClientDataAccessDialog(entityName)
            : accessToProjectDeniedDialog(entityName);
    }
    const radio = new BasicRadio([ProjectPageReason.CLIENT, ProjectPageReason.NON_CLIENT]);
    radio.select(ProjectPageReason.CLIENT); // default option
    return QueryDialog.create({
        title: `Accessing a client ${entityName}`,
        prompt: "",
        onSubmit: () => {
            const selectedId = radio.getSelectedId();
            if (selectedId === ProjectPageReason.CLIENT.id) {
                Rest.post("/recordSuperuserPageAccess.rest", {
                    reasons: [SuperuserPageReason.ACCESS_CLIENT_PROJECT],
                }).then(() => onApprove && onApprove());
            } else if (selectedId === ProjectPageReason.NON_CLIENT.id) {
                onApprove && onApprove();
            } else {
                return false;
            }
            return true;
        },
        onCancel: () => {
            onCancel && onCancel();
            return true;
        },
        submitText: "Okay",
        body: Dom.div(
            { class: "project-page-access-dialog v-spaced-8" },
            Dom.div(
                `You are not a member of this project. Accessing this ${entityName} is a Superuser `
                    + `capability that will eventually be taken away as part of Roboclip/CDSI.`,
            ),
            Dom.div(Dom.span({ class: "h7" }, "Select your reason for access")),
            Dom.node(radio),
        ),
    });
}

// a dialog that directs an everlaw admin to the "client data access" tab in
// the admin page so that they can request access to projects that involve client data.
export function redirectToClientDataAccessDialog(entityName: string): QueryDialog {
    entityName = entityName || "project";
    return QueryDialog.create({
        title: `Accessing a client ${entityName}`,
        prompt: "",
        onSubmit: () => {
            window.location.assign("/admin.do#tab=clientDataAccess");
            return true;
        },
        onCancel: () => {
            return true;
        },
        submitText: "Create an access request",
        body: Dom.div(
            { class: "project-page-access-dialog v-spaced-8" },
            Dom.div(
                `You are not a member of this ${entityName}. Please submit an internal `
                    + `access request to gain access.`,
            ),
        ),
    });
}

export function accessToProjectDeniedDialog(entityName: string): QueryDialog {
    return QueryDialog.create({
        title: `Accessing a client ${entityName}`,
        prompt: "",
        onSubmit: () => {
            return true;
        },
        onCancel: () => {
            return true;
        },
        submitText: "Okay",
        body: Dom.div(
            { class: "project-page-access-dialog v-spaced-8" },
            Dom.div(`You are not authorized to access client ${entityName}s based on your role.`),
        ),
    });
}
