import BasicRadio = require("Everlaw/UI/BasicRadio");
import Button = require("Everlaw/UI/Button");
import { ColorTokens } from "design-system";
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import { Is } from "core";
import Project = require("Everlaw/Project");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");
import SbFreeGroupsRadio = require("Everlaw/Context/SbFree/GroupsRadio");
import SbFreeUtil = require("Everlaw/Context/SbFree/Util");
import UI = require("Everlaw/UI");
import User = require("Everlaw/User");

export function promptGroupChangeIfRecommended(
    user: User,
    project: Project,
    onComplete: () => void,
) {
    if (!Is.defined(user.groups)) {
        // If impersonating from the superuser page, user groups, project groups and project
        // security are not yet loaded. Therefore, we make a call to the server to test Storybuilder
        // membership.
        Rest.get(`/parcel/${project.parcel}/inSbFreeGroup.rest`, {
            userId: user.id,
            projectId: project.id,
        }).then((res) => {
            if (res.inSbFreeGroup) {
                onComplete();
            } else {
                new SbFreeImpersonatedUserGroupChangeDialog(user, project, onComplete).show();
            }
        });
        // TODO: Is hasElevatedRole() a reasonable test to use here?
    } else if (!user.hasElevatedRole() && !SbFreeUtil.inSbFreeGroup(user)) {
        new SbFreeImpersonatedUserGroupChangeDialog(user, project, onComplete).show();
    } else {
        onComplete();
    }
}

class SbFreeImpersonatedUserGroupChangeDialog extends QueryDialog {
    private changeGroupButton: Button;
    private groupSelectRadio: BasicRadio;
    constructor(user: User, project: Project, onComplete: () => void) {
        super({
            title: "Impersonating Non Storybuilder Free User",
            prompt: Dom.span(
                Dom.b(user.display()),
                " does not have accurate Storybuilder Free permissions. ",
                " Impersonating the user now may not accurately represent this project in a Storybuilder",
                " Free context. Specify a Storybuilder Free group and select ",
                Dom.b("Change Group"),
                " to change ",
                Dom.b(`${user.display()}'s`),
                " permissions to reflect a Storybuilder",
                " Free user. ",
                warn("Note that this is permanent."),
                " Select ",
                Dom.b("Impersonate"),
                " to continue impersonating the user as normal and ",
                Dom.b("Cancel"),
                " to cancel. ",
                warn("If this message appears on a live server, there is a problem!"),
            ),
            body: Dom.div({ style: { marginTop: "8px" } }),
            submitText: "Impersonate",
            onSubmit: () => {
                onComplete();
                return true;
            },
        });
        this.buildChangeGroupAndImpersonateButton(user, project, onComplete);
        this.buildGroupSelectRadio(!Is.defined(user.groups), project);
        this.refreshChangeGroupButton();
    }
    private buildChangeGroupAndImpersonateButton(
        user: User,
        project: Project,
        onComplete: () => void,
    ) {
        this.changeGroupButton = new Button({
            label: "Change group and impersonate",
            onClick: () => {
                const group = this.groupSelectRadio.getSelected();
                if (!group) {
                    Dialog.ok("No permissions selected", "Select a permission");
                    return false;
                }
                Rest.post(project.url("sbFree/changeUserGroup.rest"), {
                    userId: user.id,
                    groupId: group.id,
                }).then(() => {
                    onComplete();
                });
                this.hide();
            },
            parent: this._buttonDiv,
            class: "important button query-submit unsafe",
            width: "140px",
        });
    }
    private buildGroupSelectRadio(query: boolean, project: Project) {
        if (query) {
            Rest.get(`/parcel/${project.parcel}/getSbFreeGroups.rest`, {
                projectId: project.id,
            }).then((res) => {
                this.groupSelectRadio = SbFreeGroupsRadio.create({
                    adminId: res.adminId,
                    basicId: res.basicId,
                });
                this.placeGroupSelectRadio();
            });
        } else {
            this.groupSelectRadio = SbFreeGroupsRadio.create();
            this.placeGroupSelectRadio();
        }
    }
    private placeGroupSelectRadio() {
        Dom.create(
            "label",
            {
                content: [Dom.b("Group"), Dom.node(this.groupSelectRadio)],
            },
            this.body,
        );
        this._toDestroy.push(this.groupSelectRadio);
        this.groupSelectRadio.onChange = () => {
            this.refreshChangeGroupButton();
        };
    }
    private refreshChangeGroupButton() {
        UI.toggleDisabled(
            this.changeGroupButton,
            !(this.groupSelectRadio && this.groupSelectRadio.getSelected()),
        );
    }
}

function warn(text: string) {
    return Dom.b(Dom.span({ style: { color: ColorTokens.TEXT_DANGER } }, text));
}
