import BasicRadio = require("Everlaw/UI/BasicRadio");
import SbFreeUtil = require("Everlaw/Context/SbFree/Util");

export function create(groups = SbFreeUtil.getSbFreeGroups()) {
    return new BasicRadio([
        {
            id: `${groups.adminId}`,
            display: "Administrator",
            subcontent: "Full permissions",
        },
        {
            id: `${groups.basicId}`,
            display: "Basic",
            subcontent: "No access to project settings",
        },
    ]);
}
