import * as Base from "Everlaw/Base";
import { PanelType } from "Everlaw/Oracle/OraclePanel";
import { OracleIngestionPipelineStatus } from "Everlaw/Oracle/OracleUtils";
import * as Project from "Everlaw/Project";
import * as Rest from "Everlaw/Rest";
import * as SearchResult from "Everlaw/SearchResult";
import * as React from "react";
import { ReactNode, useId, useRef, useState } from "react";
import {
    ButtonSize,
    CommonIcon,
    Form,
    FormSubmitButton,
    Icon,
    IconButton,
    InlineBanner,
    Paragraph,
    TextArea,
    textValidator,
    Tooltip,
    useForm,
} from "design-system";

interface FormValues {
    oracleQuery: string;
}

interface OracleSubmitFormHeaderProps {
    searchId: SearchResult.Id | undefined;
}

function OracleSubmitFormHeader({ searchId }: OracleSubmitFormHeaderProps): ReactNode {
    const aiAssistIconRef = useRef(null);
    const aiWarningTooltipId = useId();
    const entireProjectDatabaseLabel = "Entire project database";
    const documentsFromResultsTableLabel = "Documents from results table";
    const searchCount =
        Base.useStoreObject(Base.globalStore(SearchResult), searchId as SearchResult.Id)?.numResults
        ?? 0; // The '0' is included here just for safety, but should really never be visible

    return (
        <div aria-label={"Enter question or prompt"} className={"flex-horizontal"}>
            <div className={"flex-centered gap-4"}>
                <Paragraph.Semibold>Ask a question</Paragraph.Semibold>
                <CommonIcon.AIAssist
                    size={20}
                    aria-describedby={aiWarningTooltipId}
                    ref={aiAssistIconRef}
                />
                <Tooltip id={aiWarningTooltipId} target={aiAssistIconRef}>
                    This is an experimental AI feature. Results may be inaccurate, incomplete, or
                    misleading. Please double-check for accuracy.
                </Tooltip>
            </div>
            <div className={"flex-centered gap-8"}>
                <Paragraph.Semibold>Document set</Paragraph.Semibold>
                {searchId === undefined ? (
                    <Paragraph>{entireProjectDatabaseLabel}</Paragraph>
                ) : (
                    <>
                        <Paragraph>{documentsFromResultsTableLabel}</Paragraph>
                        <div className={"oracle-header-vertical-rule"} />
                        <div className={"gap-4 flex-centered"}>
                            <Icon.File size={20} />
                            <Paragraph.Number>{searchCount}</Paragraph.Number>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

interface OracleSubmitFormProps {
    ingestionStatus: OracleIngestionPipelineStatus | undefined;
    searchId: SearchResult.Id | undefined;
    panelType: PanelType | undefined;
    setPanelType: React.Dispatch<React.SetStateAction<PanelType | undefined>>;
}

export function OracleSubmitForm({
    ingestionStatus,
    searchId,
    panelType,
    setPanelType,
}: OracleSubmitFormProps): ReactNode {
    const emptySearch =
        searchId !== undefined && (Base.get(SearchResult, searchId)?.numResults ?? 0) === 0;

    const [hasQuerySubmissionError, setHasQuerySubmissionError] = useState(false);
    const [showGuidelines, setShowGuidelines] = useState(false);
    const formResult = useForm<FormValues>({
        initialValues: {
            oracleQuery: "",
        },
        validator: {
            oracleQuery: textValidator({ name: "oracleQuery", maxLength: 500 }),
        },
        onSubmit: () => {
            if (
                ingestionStatus === OracleIngestionPipelineStatus.INGESTING_FRESH
                || ingestionStatus === OracleIngestionPipelineStatus.INACTIVE_EMPTY
                || emptySearch
                || !formResult.values.oracleQuery
                || !!formResult.errors.oracleQuery
            ) {
                return;
            }
            setHasQuerySubmissionError(false);
            Rest.post(`/${Project.getCurrentId()}/oracleQuery.rest`, {
                query: formResult.values.oracleQuery,
                searchResultId: searchId,
            })
                .then(() => {
                    // Clear query box on successful submission
                    formResult.change("oracleQuery", "");
                })
                .catch((e: Rest.Failed) => {
                    switch (e.status) {
                        case 403:
                            // Reflects that Oracle isn't enabled for this project
                            // TODO: Oracle naming
                            throw new Error("Project Query is not enabled for this project");
                        case 500:
                            // Reflects that a generic failure happened
                            setHasQuerySubmissionError(true);
                            return;
                        case 503:
                            // The queue is at capacity and users should retry later
                            return;
                        default:
                            // Another unexpected error happened
                            setHasQuerySubmissionError(true);
                            // TODO: Oracle naming
                            throw new Error("Unexpected error submitting query");
                    }
                });
            // If asking a question from the empty state, switch into the "All questions" state
            if (panelType === PanelType.Empty) {
                setPanelType(PanelType.Project);
            }
        },
    });

    return (
        <Form onSubmit={formResult.submit} className={"oracle-form-submit"}>
            <div className={"flex-vertical gap-16"}>
                {(ingestionStatus === OracleIngestionPipelineStatus.INACTIVE_EMPTY
                    || emptySearch) && (
                    <InlineBanner icon={<Icon.AlertTriangle />}>
                        Your document set contains no documents. Please modify your document set to
                        ask a question.
                    </InlineBanner>
                )}
                <div className={"flex-vertical gap-8"}>
                    <OracleSubmitFormHeader searchId={searchId} />
                    <TextArea
                        placeholder={"Enter question or prompt"}
                        className={"oracle-form-submit-text-area"}
                        horizontal={false}
                        value={formResult.values.oracleQuery}
                        onBlur={(_e) => formResult.blur("oracleQuery")}
                        onChange={(e) => {
                            setHasQuerySubmissionError(false);
                            formResult.change("oracleQuery", e.target.value);
                        }}
                        label={"Ask a question"}
                        hideLabel={true}
                        disabled={
                            ingestionStatus === OracleIngestionPipelineStatus.INGESTING_FRESH
                            || ingestionStatus === OracleIngestionPipelineStatus.INACTIVE_EMPTY
                            || emptySearch
                            || formResult.loading
                        }
                        error={hasQuerySubmissionError || !!formResult.errors.oracleQuery}
                        errorMessage={
                            formResult.errors.oracleQuery
                            ?? "There was an error submitting this question"
                        }
                    />
                    <div className={"flex-horizontal"}>
                        <div className={"gap-4"}>
                            <div className={"flex-centered"}>
                                <IconButton
                                    onClick={() => {
                                        setShowGuidelines(!showGuidelines);
                                    }}
                                    aria-label={"Guidelines for writing a good question"}
                                >
                                    {showGuidelines ? (
                                        <Icon.CaretDown size={20} />
                                    ) : (
                                        <Icon.CaretRight size={20} />
                                    )}
                                </IconButton>
                                <Paragraph.Small>
                                    Guidelines for writing a good question
                                </Paragraph.Small>
                            </div>
                            {showGuidelines && (
                                <ul className={"margin-0"}>
                                    <li>
                                        <Paragraph.Small>
                                            Ask one question at a time
                                        </Paragraph.Small>
                                    </li>
                                    <li>
                                        <Paragraph.Small>
                                            It may help to start with question words like who, what,
                                            where, when, why, or how
                                        </Paragraph.Small>
                                    </li>
                                    <li>
                                        <Paragraph.Small>
                                            Be specific in your wording: use proper nouns and
                                            precise terms
                                        </Paragraph.Small>
                                    </li>
                                    <li>
                                        <Paragraph.Small>Avoid complex logic</Paragraph.Small>
                                    </li>
                                    <li>
                                        <Paragraph.Small>
                                            Narrow your document set before asking: open the results
                                            table for a document set you know will be relevant and
                                            click “Ask a question”
                                        </Paragraph.Small>
                                    </li>
                                </ul>
                            )}
                        </div>
                        <FormSubmitButton
                            disabled={
                                ingestionStatus === OracleIngestionPipelineStatus.INGESTING_FRESH
                                || ingestionStatus === OracleIngestionPipelineStatus.INACTIVE_EMPTY
                                || emptySearch
                                || !formResult.values.oracleQuery
                                || !!formResult.errors.oracleQuery
                            }
                            loading={formResult.loading}
                            size={ButtonSize.SMALL}
                            icon={<Icon.Sparkles />}
                        >
                            Send
                        </FormSubmitButton>
                    </div>
                </div>
            </div>
        </Form>
    );
}
