import Dom = require("Everlaw/Dom");
import Project = require("Everlaw/Project");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");
import SbFree = require("Everlaw/Context/SbFree");
import SbFreeImpersonatedUserGroupChange = require("Everlaw/Context/SbFree/ImpersonatedUserGroupChange");
import User = require("Everlaw/User");

interface ImpersonateOptions {
    goToOrgAdmin?: boolean;
    specificOrgId?: number;
    project?: Project;
}

export function impersonate(user: User, options: ImpersonateOptions = {}) {
    // When impersonating a user on a Storybuilder Free project, we want to verify that the user
    // to impersonate is a Storybuilder Free user. If they are not, we give the impersonator the
    // option to set the user to impersonate as either a Storybuilder Free Admin or Basic user.
    // We cannot use SbFreeBase#inSbFreeContext yet because we are not in the project context.
    if (options.project && SbFree.inProjectContext(options.project)) {
        SbFreeImpersonatedUserGroupChange.promptGroupChangeIfRecommended(
            user,
            options.project,
            () => {
                impersonateUser(user, options);
            },
        );
    } else {
        impersonateUser(user, options);
    }
}

// pass in goToOrgAdmin as true to impersonate an org admin on the org admin page
// pass in a project to impersonate a user on that project
function impersonateUser(user: User, options: ImpersonateOptions = {}) {
    let logInAs: HTMLElement;
    if (options.goToOrgAdmin) {
        logInAs = Dom.p(`Log in as user ${user.name} on their org admin page?`);
    } else if (options.project) {
        logInAs = Dom.p(`Log in as user ${user.name} on ${options.project.display()}?`);
    } else {
        logInAs = Dom.p(`User ${user.name} is not on any projects.`);
    }
    QueryDialog.create({
        title: "Impersonate user",
        prompt: [
            logInAs,
            Dom.p(
                "Note that preferences are not saved while impersonating. ",
                "Features like coding presets or full screen mode layouts may not function as expected.",
            ),
            Dom.p(
                { class: "red-text" },
                "Be careful! Any edits/changes you make ",
                "(e.g., mutating documents) will be saved and will generate analytics events.",
            ),
        ],
        submitText: "Impersonate",
        onSubmit: function () {
            Rest.post("/changeUser.rest", { user: user.id }).then(() => {
                if (options.goToOrgAdmin) {
                    const query = options.specificOrgId ? `?orgId=${options.specificOrgId}` : "";
                    location.assign(`/org.do${query}`);
                } else if (options.project && !options.project.suspended) {
                    options.project.goto("home");
                } else if (options.project && options.project.suspended && user.hasDbPerms()) {
                    options.project.goto("database");
                } else {
                    location.assign("/");
                }
            });
            return true;
        },
    });
}

export function stopImpersonating() {
    Rest.post("/endImpersonation.rest").then(() => location.reload());
}
