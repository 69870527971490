/**
 * Provides functionality for alerting users of upcoming and current downtime via toast
 * notifications.
 */
import { Announcement } from "Everlaw/Announcement";
import Base = require("Everlaw/Base");
import { Constants as C } from "core";
import Multiplex = require("Everlaw/Multiplex");
import Util = require("Everlaw/Util");
import { ToastType } from "design-system";
import { addToast } from "Everlaw/ToastBoxManager";

/**
 * Times before downtime, in minutes, at which to display toast notifications
 */
const NOTIFICATION_INTERVALS = [60, 45, 30, 15, 10, 5, 2, 1];

/**
 * If downtime has been scheduled, show the user toast notifications leading up to a release.
 */
export function scheduleDowntimePopups(nextDowntime: number) {
    const remainingTime = nextDowntime - Date.now();
    // If the downtime is more than a day away, don't bother scheduling anything.
    if (remainingTime > C.DAY || remainingTime < 0) {
        return;
    }
    // If we're less than a minute away, wait for the server to go down.
    if (remainingTime < C.MIN) {
        waitForShutdown(nextDowntime);
        return;
    }
    // Otherwise, skip to the next popup time e.g. if we are 50 minutes out, skip to 45.
    const nextPopupInterval: number = NOTIFICATION_INTERVALS.filter(
        (i) => i * C.MIN <= remainingTime,
    )[0];
    // Schedule the popup (e.g. for 45m before the downtime), and re-run the loop.
    setTimeout(
        () => {
            addToast({
                icon: "Clock",
                title: "Scheduled downtime",
                children: `Everlaw is going down for maintenance in ${Util.countOf(nextPopupInterval, "minute")}`,
            });
            scheduleDowntimePopups(nextDowntime);
        },
        remainingTime - nextPopupInterval * C.MIN,
    );
}

/**
 * Replace existing Announcements with temporary notice. The Announcements Bar subscribes to
 * Announcement updates, and will reset after the mux reconnects.
 * @param message announcement text
 * @param duration when the message will expire, which will trigger a refresh
 */
function showTemporaryAnnouncement(message: string, duration: number) {
    Base.reset(Announcement, [
        new Announcement({
            id: 0, // Dummy id.
            startTime: Date.now(),
            endTime: Date.now() + duration,
            dismissible: false,
            message,
            showFor: Announcement.ShowFor.ALL_CASES,
        }),
    ]);
}

/**
 * Wait for long polling to fail, and display a popup and announcement. Give up after 5 minutes.
 */
function waitForShutdown(nextDowntime: number) {
    showTemporaryAnnouncement(
        "Everlaw is going down for maintenance in less than a minute.",
        5 * C.MIN,
    );
    if (Multiplex.pollFailed) {
        // The server is down, but the clock icon should be cached from the previous popups.
        addToast({
            icon: "Clock",
            title: "Scheduled downtime",
            children: "Everlaw is down for maintenance.",
        });
        showTemporaryAnnouncement("Everlaw is down for scheduled maintenance.", 2 * C.HR);
        waitForRestart();
    } else {
        const timeSinceShutdown = Date.now() - nextDowntime;
        if (timeSinceShutdown < 5 * C.MIN) {
            setTimeout(() => waitForShutdown(nextDowntime), 10 * C.SEC);
        }
    }
}

/**
 * Wait for long polling to succeed, and display a popup.
 */
function waitForRestart() {
    if (!Multiplex.pollFailed) {
        addToast({
            title: "Scheduled downtime",
            children: "Everlaw is back up!",
            type: ToastType.SUCCESS,
        });
        Base.reset(Announcement);
    } else {
        setTimeout(waitForRestart, 30 * C.SEC);
    }
}
