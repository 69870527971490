import * as Base from "Everlaw/Base";
import { ObjJson } from "Everlaw/Base";
import * as Multiplex from "Everlaw/Multiplex";
import { textWithoutCitations } from "Everlaw/Oracle/OracleCitationComponents";
import * as Rest from "Everlaw/Rest";
import { Subscription } from "Everlaw/Multiplex";
import * as BaseResultsPage from "Everlaw/BaseResultsPage";
import * as Project from "Everlaw/Project";
import { addToast } from "Everlaw/ToastBoxManager";
import { copyPlainTextToClipboard } from "Everlaw/UI/Copyable";
import * as Dialog from "Everlaw/UI/Dialog";

export enum OracleQueryStep {
    QUESTION_SUBMISSION,
    QUESTION_SEARCH,
    EXCERPTS_EXTRACTION,
    FACTOIDS_RESPONSE,
    ANSWER_CONSOLIDATION,
}

/**
 * This enum must be kept in sync with OracleIngestionPipeline.java.
 */
export enum OracleIngestionPipelineStatus {
    INGESTING_FRESH,
    INGESTING_UPDATE,
    INACTIVE_EMPTY,
    INACTIVE_INGESTED,
}

export function subscribeToOracleByProject(): Subscription {
    return Multiplex.subscribe({
        method: "POST",
        url: "subscribeToOracleByProject.rest",
        success: (sub: Multiplex.Subscription): void => {
            sub.begin((data) => {
                data.currentStep = OracleQueryStep[data.currentStep];
                Base.set(OracleInfo, data);
            });
            fetchInProgressCompletionsByProject();
        },
    });
}

export function subscribeToOracleByUser(): Subscription {
    return Multiplex.subscribe({
        method: "POST",
        url: "subscribeToOracleByUser.rest",
        success: (sub: Multiplex.Subscription): void => {
            sub.begin((data) => {
                data.currentStep = OracleQueryStep[data.currentStep];
                Base.set(OracleInfo, data);
            });
            fetchInProgressCompletionsByUser();
        },
    });
}

export function getFactoids(factIds: number[], submissionId: number): Promise<void> {
    return Rest.get("getOracleFactoids.rest", { factIds, submissionId }).then((data) => {
        Base.set(OracleFactoidObj, data);
    });
}

export function getNumPotentiallyRelevantDocs(submissionId: number): void {
    Rest.get("getOracleNumPotentiallyRelevantDocs.rest", { submissionId }).then(
        (numRelevantDocs) => {
            Base.set(OracleInfo, {
                id: submissionId,
                searchResult: { numRelevantDocs: numRelevantDocs },
            } as ObjJson);
        },
    );
}

/**
 * Fetches the completed questions by project.
 * @param excludeIds Specify the ids of questions that you do not want load onto the frontend if
 * they are already loaded.
 */
export function getCompletedQuestionsByProject(excludeIds: number[]): Promise<void> {
    return Rest.post("getCompletedQuestionsByProject.rest", { excludeIds }).then((data) => {
        data.forEach(
            // converting string sent by backend to OracleQueryStep enum
            (info: { currentStep: keyof typeof OracleQueryStep | OracleQueryStep }) =>
                (info.currentStep =
                    OracleQueryStep[info.currentStep as keyof typeof OracleQueryStep]),
        );
        data.currentStep = OracleQueryStep[data.currentStep];
        Base.set(OracleInfo, data);
    });
}

/**
 * Fetches the completed questions by user.
 * @param excludeIds Specify the ids of questions that you do not want load onto the frontend if
 * they are already loaded.
 */
export function getCompletedQuestionsByUser(excludeIds: number[]): Promise<void> {
    return Rest.post("getCompletedQuestionsByUser.rest", { excludeIds }).then((data) => {
        data.forEach(
            // converting string sent by backend to OracleQueryStep enum
            (info: { currentStep: keyof typeof OracleQueryStep | OracleQueryStep }) =>
                (info.currentStep =
                    OracleQueryStep[info.currentStep as keyof typeof OracleQueryStep]),
        );
        data.currentStep = OracleQueryStep[data.currentStep];
        Base.set(OracleInfo, data);
    });
}

export function getAllRankedFactoidsForAnswer(
    submissionId: number,
    answerId: number,
): Promise<OracleInfo> {
    return Rest.get("getAllRankedFactoidsForAnswer.rest", { submissionId, answerId }).then(
        (data) => {
            Base.set(OracleFactoidObj, data.factoids);
            return Base.set(OracleInfo, {
                id: submissionId,
                allFactoids: data.docInfo,
            } as ObjJson);
        },
    );
}

export function getCitationsForAnswer(submissionId: number, answerId: number): void {
    Rest.get("getCitationsForAnswer.rest", { submissionId, answerId }).then((data) => {
        Base.set(OracleInfo, {
            id: submissionId,
            answerCitations: data,
        } as ObjJson);
    });
}

export function fetchInProgressCompletionsByProject(): void {
    Rest.post("fetchInProgressCompletionsByProject.rest", {}).catch(() => {
        throw new Error("Error fetching in progress answers by project");
    });
}

export function fetchInProgressCompletionsByUser(): void {
    Rest.post("fetchInProgressCompletionsByUser.rest", {}).catch(() => {
        throw new Error("Error fetching in progress answers by user");
    });
}

export function getSearchResultURL(submissionId: number): Promise<void> {
    return Rest.get("getOracleSearchResultURL.rest", { submissionId }).then((url) => {
        Base.set(OracleInfo, { id: submissionId, searchResultUrl: url } as ObjJson);
    });
}

export function deleteInfo(info: OracleInfo): Promise<void> {
    return Rest.post("deleteBySubmissionId.rest", { submissionId: info.id })
        .then(() => {
            Base.remove(info);
        })
        .catch((e: Rest.Failed) => {
            // When the response has a user-friendly message, show it to the user
            if (e.isUserFriendlyMessage) {
                e.show();
            } else {
                Dialog.ok(
                    "Error",
                    "You do not have permissions to delete this submission.",
                    undefined,
                    "456px",
                );
            }
        });
}

export function getCaseContext(): Promise<string> {
    return Rest.get("getCaseContext.rest");
}

export function setCaseContext(caseContext: string): Promise<void> {
    return Rest.post("setCaseContext.rest", { caseContext });
}

/**
 * Returns the current ingestion status.
 */
export async function getIngestionStatus(): Promise<OracleIngestionPipelineStatus> {
    const statusKey = (await Rest.get(
        "getIngestionPipelineStatus.rest",
    )) as keyof typeof OracleIngestionPipelineStatus;
    const statusVal = OracleIngestionPipelineStatus[statusKey];
    if (statusVal === undefined) {
        throw new Error("Unmatched ingestion status received from backend: " + statusKey);
    }
    return OracleIngestionPipelineStatus[statusKey];
}

export function openDocInNewWindow(submissionId: number, docId: number | undefined): void {
    if (!docId) {
        return;
    }
    Rest.post("openDocFromOracleSubmission.rest", { submissionId, docId }).then(() =>
        BaseResultsPage.openInNewDoc(
            Project.getCurrentProject().urlFor("review", { doc: docId }),
            "oracle",
        ),
    );
}

export function copyOracleGenerationToClipboard(info: OracleInfo): void {
    Rest.post("copyOracleGenerationToClipboard.rest", { submissionId: info.id as number }).then(
        () =>
            copyPlainTextToClipboard(textWithoutCitations({ info })).then(() => {
                addToast({
                    icon: "CircleCheck",
                    title: "Response copied to clipboard",
                });
            }),
    );
}

export function countQuestionsByUser(): Promise<number> {
    return Rest.get("countQuestionsByUser.rest");
}

export function countQuestionsByProject(): Promise<number> {
    return Rest.get("countQuestionsByProject.rest");
}

export class OracleFactoidObj extends Base.Object {
    get className(): string {
        return "OracleFactoidText";
    }
    quote: string;
    relevanceScore: number;
    referenceId: number | undefined;

    constructor(params: OracleInfo) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: OracleInfo): void {
        Object.assign(this, params);
    }
}

export class OracleInfo extends Base.Object {
    get className(): string {
        return "OracleInfo";
    }
    userId: number;
    currentStep: OracleQueryStep;
    inQueue: boolean;
    questionSubmission: QuestionSubmissionInfo;
    searchResult: SearchResultInfo;
    excerptsExtraction: ExcerptsExtractionInfo;
    factoidsResponse: FactoidsResponseInfo;
    consolidatedAnswer: ConsolidatedAnswerInfo;
    // The following fields are separate because they are lazy loaded
    answerCitations: OracleCitedFactoid[];
    allFactoids: OracleFactoid[];
    searchResultUrl: string;

    constructor(params: OracleInfo) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: OracleInfo): void {
        Object.assign(this, params);
    }
}

// for all relevant factoids
export interface OracleFactoid {
    factId: number;
    docId: number;
    batesDisplay: string;
}

// for factoids used in citation
export interface OracleCitedFactoid {
    referenceId: number;
    factId: number;
    docId: number;
    batesDisplay: string;
    displayId: number; // citation number to be displayed instead of referenceId
}

interface SearchResultInfo {
    numRelevantDocs: number; // This is lazy loaded for already completed answers
}

interface ExcerptsExtractionInfo {
    numDocsEvaluated: number;
}

interface FactoidsResponseInfo {
    factIds: number[];
}

interface QuestionSubmissionInfo {
    questionText: string;
    timestamp: number;
    submissionId: number;
    searchResultId: number;
    name: string;
    hasQueryError: boolean;
    hasQueueingError: boolean;
}

interface ConsolidatedAnswerInfo {
    answerId: number;
    answerText: string;
    completionId: number;
}
