import Multiplex = require("Everlaw/Multiplex");
import { userHasSafari } from "core";

// Magic numbers
const eocdSig = 0x06054b50; // End of central directory (EOCD) signature
const z64LocatorSig = 0x07064b50; // zip64 EOCD locator signature
const z64EOCDSig = 0x06064b50; // zip64 EOCD signature
const centralDirSig = 0x02014b50; // central directory entry signature
const unicodePathKey = 0x7075; // Key for optional unicode path in "extra info"
const z64LocatorLength = 20; // Size of zip64 EOCD locator in bytes, see findAndCheckZip64Locator
const eocdNoCommentSize = 22; // Size of EOCD in bytes

// Decoding table for CP437 encoding.
// See https://en.wikipedia.org/wiki/Code_page_437
const cp437ToUnicode: { [cp: number]: string } = {
    0x00: String.fromCharCode(0x0000),
    0x01: String.fromCharCode(0x0001),
    0x02: String.fromCharCode(0x0002),
    0x03: String.fromCharCode(0x0003),
    0x04: String.fromCharCode(0x0004),
    0x05: String.fromCharCode(0x0005),
    0x06: String.fromCharCode(0x0006),
    0x07: String.fromCharCode(0x0007),
    0x08: String.fromCharCode(0x0008),
    0x09: String.fromCharCode(0x0009),
    0x0a: String.fromCharCode(0x000a),
    0x0b: String.fromCharCode(0x000b),
    0x0c: String.fromCharCode(0x000c),
    0x0d: String.fromCharCode(0x000d),
    0x0e: String.fromCharCode(0x000e),
    0x0f: String.fromCharCode(0x000f),
    0x10: String.fromCharCode(0x0010),
    0x11: String.fromCharCode(0x0011),
    0x12: String.fromCharCode(0x0012),
    0x13: String.fromCharCode(0x0013),
    0x14: String.fromCharCode(0x0014),
    0x15: String.fromCharCode(0x0015),
    0x16: String.fromCharCode(0x0016),
    0x17: String.fromCharCode(0x0017),
    0x18: String.fromCharCode(0x0018),
    0x19: String.fromCharCode(0x0019),
    0x1a: String.fromCharCode(0x001a),
    0x1b: String.fromCharCode(0x001b),
    0x1c: String.fromCharCode(0x001c),
    0x1d: String.fromCharCode(0x001d),
    0x1e: String.fromCharCode(0x001e),
    0x1f: String.fromCharCode(0x001f),
    0x20: String.fromCharCode(0x0020),
    0x21: String.fromCharCode(0x0021),
    0x22: String.fromCharCode(0x0022),
    0x23: String.fromCharCode(0x0023),
    0x24: String.fromCharCode(0x0024),
    0x25: String.fromCharCode(0x0025),
    0x26: String.fromCharCode(0x0026),
    0x27: String.fromCharCode(0x0027),
    0x28: String.fromCharCode(0x0028),
    0x29: String.fromCharCode(0x0029),
    0x2a: String.fromCharCode(0x002a),
    0x2b: String.fromCharCode(0x002b),
    0x2c: String.fromCharCode(0x002c),
    0x2d: String.fromCharCode(0x002d),
    0x2e: String.fromCharCode(0x002e),
    0x2f: String.fromCharCode(0x002f),
    0x30: String.fromCharCode(0x0030),
    0x31: String.fromCharCode(0x0031),
    0x32: String.fromCharCode(0x0032),
    0x33: String.fromCharCode(0x0033),
    0x34: String.fromCharCode(0x0034),
    0x35: String.fromCharCode(0x0035),
    0x36: String.fromCharCode(0x0036),
    0x37: String.fromCharCode(0x0037),
    0x38: String.fromCharCode(0x0038),
    0x39: String.fromCharCode(0x0039),
    0x3a: String.fromCharCode(0x003a),
    0x3b: String.fromCharCode(0x003b),
    0x3c: String.fromCharCode(0x003c),
    0x3d: String.fromCharCode(0x003d),
    0x3e: String.fromCharCode(0x003e),
    0x3f: String.fromCharCode(0x003f),
    0x40: String.fromCharCode(0x0040),
    0x41: String.fromCharCode(0x0041),
    0x42: String.fromCharCode(0x0042),
    0x43: String.fromCharCode(0x0043),
    0x44: String.fromCharCode(0x0044),
    0x45: String.fromCharCode(0x0045),
    0x46: String.fromCharCode(0x0046),
    0x47: String.fromCharCode(0x0047),
    0x48: String.fromCharCode(0x0048),
    0x49: String.fromCharCode(0x0049),
    0x4a: String.fromCharCode(0x004a),
    0x4b: String.fromCharCode(0x004b),
    0x4c: String.fromCharCode(0x004c),
    0x4d: String.fromCharCode(0x004d),
    0x4e: String.fromCharCode(0x004e),
    0x4f: String.fromCharCode(0x004f),
    0x50: String.fromCharCode(0x0050),
    0x51: String.fromCharCode(0x0051),
    0x52: String.fromCharCode(0x0052),
    0x53: String.fromCharCode(0x0053),
    0x54: String.fromCharCode(0x0054),
    0x55: String.fromCharCode(0x0055),
    0x56: String.fromCharCode(0x0056),
    0x57: String.fromCharCode(0x0057),
    0x58: String.fromCharCode(0x0058),
    0x59: String.fromCharCode(0x0059),
    0x5a: String.fromCharCode(0x005a),
    0x5b: String.fromCharCode(0x005b),
    0x5c: String.fromCharCode(0x005c),
    0x5d: String.fromCharCode(0x005d),
    0x5e: String.fromCharCode(0x005e),
    0x5f: String.fromCharCode(0x005f),
    0x60: String.fromCharCode(0x0060),
    0x61: String.fromCharCode(0x0061),
    0x62: String.fromCharCode(0x0062),
    0x63: String.fromCharCode(0x0063),
    0x64: String.fromCharCode(0x0064),
    0x65: String.fromCharCode(0x0065),
    0x66: String.fromCharCode(0x0066),
    0x67: String.fromCharCode(0x0067),
    0x68: String.fromCharCode(0x0068),
    0x69: String.fromCharCode(0x0069),
    0x6a: String.fromCharCode(0x006a),
    0x6b: String.fromCharCode(0x006b),
    0x6c: String.fromCharCode(0x006c),
    0x6d: String.fromCharCode(0x006d),
    0x6e: String.fromCharCode(0x006e),
    0x6f: String.fromCharCode(0x006f),
    0x70: String.fromCharCode(0x0070),
    0x71: String.fromCharCode(0x0071),
    0x72: String.fromCharCode(0x0072),
    0x73: String.fromCharCode(0x0073),
    0x74: String.fromCharCode(0x0074),
    0x75: String.fromCharCode(0x0075),
    0x76: String.fromCharCode(0x0076),
    0x77: String.fromCharCode(0x0077),
    0x78: String.fromCharCode(0x0078),
    0x79: String.fromCharCode(0x0079),
    0x7a: String.fromCharCode(0x007a),
    0x7b: String.fromCharCode(0x007b),
    0x7c: String.fromCharCode(0x007c),
    0x7d: String.fromCharCode(0x007d),
    0x7e: String.fromCharCode(0x007e),
    0x7f: String.fromCharCode(0x007f),
    0x80: String.fromCharCode(0x00c7),
    0x81: String.fromCharCode(0x00fc),
    0x82: String.fromCharCode(0x00e9),
    0x83: String.fromCharCode(0x00e2),
    0x84: String.fromCharCode(0x00e4),
    0x85: String.fromCharCode(0x00e0),
    0x86: String.fromCharCode(0x00e5),
    0x87: String.fromCharCode(0x00e7),
    0x88: String.fromCharCode(0x00ea),
    0x89: String.fromCharCode(0x00eb),
    0x8a: String.fromCharCode(0x00e8),
    0x8b: String.fromCharCode(0x00ef),
    0x8c: String.fromCharCode(0x00ee),
    0x8d: String.fromCharCode(0x00ec),
    0x8e: String.fromCharCode(0x00c4),
    0x8f: String.fromCharCode(0x00c5),
    0x90: String.fromCharCode(0x00c9),
    0x91: String.fromCharCode(0x00e6),
    0x92: String.fromCharCode(0x00c6),
    0x93: String.fromCharCode(0x00f4),
    0x94: String.fromCharCode(0x00f6),
    0x95: String.fromCharCode(0x00f2),
    0x96: String.fromCharCode(0x00fb),
    0x97: String.fromCharCode(0x00f9),
    0x98: String.fromCharCode(0x00ff),
    0x99: String.fromCharCode(0x00d6),
    0x9a: String.fromCharCode(0x00dc),
    0x9b: String.fromCharCode(0x00a2),
    0x9c: String.fromCharCode(0x00a3),
    0x9d: String.fromCharCode(0x00a5),
    0x9e: String.fromCharCode(0x20a7),
    0x9f: String.fromCharCode(0x0192),
    0xa0: String.fromCharCode(0x00e1),
    0xa1: String.fromCharCode(0x00ed),
    0xa2: String.fromCharCode(0x00f3),
    0xa3: String.fromCharCode(0x00fa),
    0xa4: String.fromCharCode(0x00f1),
    0xa5: String.fromCharCode(0x00d1),
    0xa6: String.fromCharCode(0x00aa),
    0xa7: String.fromCharCode(0x00ba),
    0xa8: String.fromCharCode(0x00bf),
    0xa9: String.fromCharCode(0x2310),
    0xaa: String.fromCharCode(0x00ac),
    0xab: String.fromCharCode(0x00bd),
    0xac: String.fromCharCode(0x00bc),
    0xad: String.fromCharCode(0x00a1),
    0xae: String.fromCharCode(0x00ab),
    0xaf: String.fromCharCode(0x00bb),
    0xb0: String.fromCharCode(0x2591),
    0xb1: String.fromCharCode(0x2592),
    0xb2: String.fromCharCode(0x2593),
    0xb3: String.fromCharCode(0x2502),
    0xb4: String.fromCharCode(0x2524),
    0xb5: String.fromCharCode(0x2561),
    0xb6: String.fromCharCode(0x2562),
    0xb7: String.fromCharCode(0x2556),
    0xb8: String.fromCharCode(0x2555),
    0xb9: String.fromCharCode(0x2563),
    0xba: String.fromCharCode(0x2551),
    0xbb: String.fromCharCode(0x2557),
    0xbc: String.fromCharCode(0x255d),
    0xbd: String.fromCharCode(0x255c),
    0xbe: String.fromCharCode(0x255b),
    0xbf: String.fromCharCode(0x2510),
    0xc0: String.fromCharCode(0x2514),
    0xc1: String.fromCharCode(0x2534),
    0xc2: String.fromCharCode(0x252c),
    0xc3: String.fromCharCode(0x251c),
    0xc4: String.fromCharCode(0x2500),
    0xc5: String.fromCharCode(0x253c),
    0xc6: String.fromCharCode(0x255e),
    0xc7: String.fromCharCode(0x255f),
    0xc8: String.fromCharCode(0x255a),
    0xc9: String.fromCharCode(0x2554),
    0xca: String.fromCharCode(0x2569),
    0xcb: String.fromCharCode(0x2566),
    0xcc: String.fromCharCode(0x2560),
    0xcd: String.fromCharCode(0x2550),
    0xce: String.fromCharCode(0x256c),
    0xcf: String.fromCharCode(0x2567),
    0xd0: String.fromCharCode(0x2568),
    0xd1: String.fromCharCode(0x2564),
    0xd2: String.fromCharCode(0x2565),
    0xd3: String.fromCharCode(0x2559),
    0xd4: String.fromCharCode(0x2558),
    0xd5: String.fromCharCode(0x2552),
    0xd6: String.fromCharCode(0x2553),
    0xd7: String.fromCharCode(0x256b),
    0xd8: String.fromCharCode(0x256a),
    0xd9: String.fromCharCode(0x2518),
    0xda: String.fromCharCode(0x250c),
    0xdb: String.fromCharCode(0x2588),
    0xdc: String.fromCharCode(0x2584),
    0xdd: String.fromCharCode(0x258c),
    0xde: String.fromCharCode(0x2590),
    0xdf: String.fromCharCode(0x2580),
    0xe0: String.fromCharCode(0x03b1),
    0xe1: String.fromCharCode(0x00df),
    0xe2: String.fromCharCode(0x0393),
    0xe3: String.fromCharCode(0x03c0),
    0xe4: String.fromCharCode(0x03a3),
    0xe5: String.fromCharCode(0x03c3),
    0xe6: String.fromCharCode(0x00b5),
    0xe7: String.fromCharCode(0x03c4),
    0xe8: String.fromCharCode(0x03a6),
    0xe9: String.fromCharCode(0x0398),
    0xea: String.fromCharCode(0x03a9),
    0xeb: String.fromCharCode(0x03b4),
    0xec: String.fromCharCode(0x221e),
    0xed: String.fromCharCode(0x03c6),
    0xee: String.fromCharCode(0x03b5),
    0xef: String.fromCharCode(0x2229),
    0xf0: String.fromCharCode(0x2261),
    0xf1: String.fromCharCode(0x00b1),
    0xf2: String.fromCharCode(0x2265),
    0xf3: String.fromCharCode(0x2264),
    0xf4: String.fromCharCode(0x2320),
    0xf5: String.fromCharCode(0x2321),
    0xf6: String.fromCharCode(0x00f7),
    0xf7: String.fromCharCode(0x2248),
    0xf8: String.fromCharCode(0x00b0),
    0xf9: String.fromCharCode(0x2219),
    0xfa: String.fromCharCode(0x00b7),
    0xfb: String.fromCharCode(0x221a),
    0xfc: String.fromCharCode(0x207f),
    0xfd: String.fromCharCode(0x00b2),
    0xfe: String.fromCharCode(0x25a0),
    0xff: String.fromCharCode(0x00a0),
};

// Decode a Uint8Array of a string encoded with CP437
function cp437Decode(buff: Uint8Array) {
    const res: string[] = [];
    // buff.forEach does not work in Safari?
    for (let i = 0; i < buff.length; i++) {
        res.push(cp437ToUnicode[buff[i]]);
    }
    return res.join("");
}

/**
 * Decode the given array buffer to a string, either as utf-8 or CP437.
 */
function decode(ab: ArrayBuffer, isUtf8: boolean) {
    if (isUtf8) {
        // Use the built in FileReader.readAsText, which uses utf-8 by default.
        return new Promise<String>((resolve, reject) => {
            const fr = new FileReader();
            fr.addEventListener("loadend", () => {
                resolve(<string>fr.result);
            });
            fr.addEventListener("error", reject);
            fr.readAsText(new Blob([ab]));
        });
    } else {
        // Assume CP437.
        return Promise.resolve(cp437Decode(new Uint8Array(ab)));
    }
}

/**
 * This will support long values up to 2**53 - 1 (max precision of an int in javascript), which isn't
 * full Zip64 but hopefully good enough.
 */
function readLong(dv: DataView, idx: number) {
    const low = dv.getUint32(idx, true);
    const high = dv.getUint32(idx + 4, true);
    return low + high * 4294967296; // low + (high << 32), except that bitwise operators coerce to 32 bits...
}

interface ZipEntry {
    size: number;
    filename: string;
    isDir: boolean;
}

/**
 * Zip parser class.
 * Right now, only parses enough to get the central directory entries - filenames and file sizes.
 * The file data itself is NOT read in.
 * Handles Zip64, a few funky format issues (data prepended, appended to the zip, different ways
 * to store filenames).
 * For detailed format info, see https://pkware.cachefly.net/webdocs/casestudies/APPNOTE.TXT
 * The overall format:
 *     [local file header 1]
 *     [encryption header 1]
 *     [file data 1]
 *     [data descriptor 1]
 *     ...
 *     [local file header n]
 *     [encryption header n]
 *     [file data n]
 *     [data descriptor n]
 *     [archive decryption header]
 *     [archive extra data record]
 *     [central directory header 1]
 *     ...
 *     [central directory header n]
 *     [optional zip64 end of central directory record]
 *     [optional zip64 end of central directory locator]
 *     [end of central directory record]
 * All we care about is the tail end, from [central directory header 1] to
 * [end of central directory record]. The file entry information is in the CD headers, and the location
 * of the central directory block is in the EOCD record (or zip64 version).
 */
class Zip {
    // Will be set after succesful parsing.
    entries: ZipEntry[];
    // How many entries do we expect?
    private entryCount: number;
    // Reported size of the central directory
    private cdSize: number;
    // Reported offset (position in file) of the central directory start
    private cdOffset: number;
    // The actual offset of the start of the normal EOCD
    private eocdOffset: number;
    // The actual offset of the start of the zip64 EOCD (if it exists)
    private z64EOCDOffset: number;
    // The shift (if any) we discovered between reported and actual positions in the file - e.g. as
    // would be caused by data prepended to the file. e.g. if 10 extra bytes were prepended to the
    // file, the zip records might say to go to byte offset 100, when the true position in the file
    // would be 110.
    // If this is non-zero, any reported offsets should be adjusted as:
    // actual = reported + this.shift
    private shift = 0;
    // Have we loaded the normal EOCD yet?
    private loadedEOCD = false;
    constructor(private file: File) {}
    loadEntries() {
        return this.loadNoCommentEOCD() // Fast-path: try and load the EOCD assuming it has no comment entry
            .then(() => this.maybeLoadFullEOCD()) // Look for the full EOCD if we haven't already found it
            .then(() => this.ensureEOCDLoaded()) // Make sure we found something
            .then(() => this.maybeLoadZip64EOCD()) // Look for a zip64 EOCD
            .then(() => this.loadCentralDirectory()) // Load the central directory
            .then(() => this.entries);
    }
    /**
     * Where does the central directory end (actual offset, not reported)?
     */
    private cdEnd() {
        return this.z64EOCDOffset || this.eocdOffset;
    }
    private loadCentralDirectory() {
        return this.loadBlob(this.file.slice(this.cdOffset + this.shift, this.cdEnd())).then(
            (dv) => {
                return this.loadAllEntries(dv);
            },
        );
    }
    /**
     * Parse a central directory entry from the given dataview starting at the given index.
     * Format:
     *  central file header signature   4 bytes  (0x02014b50)
     *  version made by                 2 bytes
     *  version needed to extract       2 bytes
     *  general purpose bit flag        2 bytes
     *  compression method              2 bytes
     *  last mod file time              2 bytes
     *  last mod file date              2 bytes
     *  crc-32                          4 bytes
     *  compressed size                 4 bytes
     *  uncompressed size               4 bytes
     *  file name length                2 bytes
     *  extra field length              2 bytes
     *  file comment length             2 bytes
     *  disk number start               2 bytes
     *  internal file attributes        2 bytes
     *  external file attributes        4 bytes
     *  relative offset of local header 4 bytes
     *
     *  file name (variable size)
     *  extra field (variable size)
     *  file comment (variable size)
     */
    private parseEntry(dv: DataView, idx: number) {
        const infoBit = dv.getUint16(idx + 8, true);
        const filenameSize = dv.getUint16(idx + 28, true);
        const extraSize = dv.getUint16(idx + 30, true);
        const commentSize = dv.getUint16(idx + 32, true);
        // Optional flag indicating utf8 encoding.
        let isUtf8 = !!(infoBit & 0x800);
        let toParse = dv.buffer.slice(idx + 46, idx + 46 + filenameSize);
        if (!isUtf8 && extraSize > 0) {
            // Try and read the optional "extra fields" unicode path.
            const extraFields = this.parseExtraFields(dv, idx + 46 + filenameSize, extraSize);
            if (unicodePathKey in extraFields) {
                isUtf8 = true;
                // The unicode path data has a 1 byte version and 4 byte checksum, then the actual path.
                toParse = dv.buffer.slice(
                    extraFields[unicodePathKey].start + 5,
                    extraFields[unicodePathKey].end,
                );
            }
            // Otherwise, this might be CP437 (if this adheres to the spec), or some random encoding.
        }
        const promise = decode(toParse, isUtf8).then((filename) => {
            return <ZipEntry>{
                size: dv.getUint32(idx + 24, true),
                filename: filename,
                isDir:
                    !!(dv.getUint32(idx + 36, true) & 0x0010)
                    || (filename && filename.charAt(filename.length - 1) === "/"),
            };
        });
        return {
            promise: promise,
            length: 46 + filenameSize + extraSize + commentSize,
        };
    }
    /**
     * Parse the "Extra fields" field, a series of entries of the form:
     *  Extra field key            2 bytes
     *  extra field data length    2 bytes
     *  extra field data          (variable size)
     */
    private parseExtraFields(dv: DataView, idx: number, len: number) {
        let curr = 0;
        const res: { [key: number]: { start: number; end: number } } = {};
        while (curr < len) {
            const key = dv.getUint16(idx + curr, true);
            const len = dv.getUint16(idx + curr + 2, true);
            res[key] = {
                start: idx + curr + 4,
                end: idx + curr + 4 + len,
            };
            curr += 4 + len;
        }
        return res;
    }
    /**
     * Look for a central directory in the given DataView and parse its entries.
     * The DataView might have leading non-CD data.
     * Once we find a central directory header, we assume everything from that point on is part of
     * the central directory.
     */
    private loadAllEntries(dv: DataView) {
        const promises: Promise<ZipEntry>[] = [];
        let idx = 0;
        let foundFirst = false;
        while (idx < dv.byteLength) {
            if (dv.getUint32(idx, true) === centralDirSig) {
                const parsed = this.parseEntry(dv, idx);
                promises.push(parsed.promise);
                idx += parsed.length;
                foundFirst = true;
            } else if (foundFirst) {
                return Promise.reject("Invalid central directory");
            } else {
                // Skip this invalid leading data.
                idx++;
            }
        }
        return Promise.all(promises).then((ze) => {
            this.entries = ze;
        });
    }
    /**
     * Load the last EOCD-sized block of data from the file and see if it looks like an EOCD.
     */
    private loadNoCommentEOCD() {
        const noCommentBlock = this.file.slice(this.file.size - eocdNoCommentSize, this.file.size);
        return this.loadBlob(noCommentBlock).then((dv) => {
            this.findAndCheckEOCD(dv);
        });
    }
    /**
     * Look for a normal EOCD in the given dataview, searching from the end back to the beginning.
     */
    private findAndCheckEOCD(dv: DataView) {
        for (let i = dv.byteLength - eocdNoCommentSize; i >= 0; i--) {
            const last = dv.getUint8(i);
            if (last === 0x50 && dv.getUint32(i, true) === eocdSig) {
                const newBuff = dv.buffer.slice(i, dv.byteLength);
                this.parseEOCD(new DataView(newBuff));
                this.eocdOffset = this.file.size - newBuff.byteLength;
                this.loadedEOCD = true;
                return;
            }
        }
    }
    /**
     * Parse the normal zip EOCD block at the given DataView.
     * All zips have this block, but some will have placeholder values (e.g. for the central directory
     * offset) for which we'll have to go to the zip64 EOCD block.
     * Format:
     *   end of central dir signature    4 bytes  (0x06054b50)
     *   number of this disk             2 bytes
     *   number of the disk with the
     *   start of the central directory  2 bytes
     *   total number of entries in the
     *   central directory on this disk  2 bytes
     *   total number of entries in
     *   the central directory           2 bytes
     *   size of the central directory   4 bytes
     *   offset of start of central
     *   directory with respect to
     *   the starting disk number        4 bytes
     *   .ZIP file comment length        2 bytes
     *   .ZIP file comment       (variable size)
     */
    private parseEOCD(dv: DataView) {
        this.entryCount = dv.getUint16(10, true);
        this.cdSize = dv.getUint32(12, true);
        this.cdOffset = dv.getUint32(16, true);
    }
    private maybeLoadFullEOCD() {
        if (!this.loadedEOCD) {
            const maxEOCDSize = 65536 + eocdNoCommentSize; // max 2**16 byte comment
            const left = Math.max(0, this.file.size - maxEOCDSize);
            const fullEOCD = this.file.slice(left, this.file.size);
            return this.loadBlob(fullEOCD).then((dv) => {
                this.findAndCheckEOCD(dv);
            });
        }
    }
    private ensureEOCDLoaded() {
        if (!this.loadedEOCD) {
            return Promise.reject("Unable to load EOCD.");
        }
        return Promise.resolve();
    }
    /**
     * Look for zip64 EOCD and EOCD locator blocks.
     */
    private maybeLoadZip64EOCD() {
        // If there isn't enough room for the zip64 blocks, skip this step.
        if (this.eocdOffset >= 32) {
            const eocdLocatorBlock = this.file.slice(
                this.eocdOffset - z64LocatorLength,
                this.eocdOffset,
            );
            return this.loadBlob(eocdLocatorBlock).then((dv) => this.findAndCheckZip64Locator(dv));
        }
    }
    /**
     * Try and load the zip64 EOCD locator block (fixed length).
     * Format:
     *   signature                       4 bytes  (0x07064b50)
     *   number of the disk with the
     *   start of the zip64 end of
     *   central directory               4 bytes
     *   relative offset of the zip64
     *   end of central directory record 8 bytes
     *   total number of disks           4 bytes
     */
    private findAndCheckZip64Locator(dv: DataView) {
        if (dv.getUint32(0, true) === z64LocatorSig) {
            const diskCount = dv.getUint32(16, true);
            if (diskCount !== 1) {
                return Promise.reject("Multi-disk zip archives are not supported.");
            } else {
                const reportedZ64EOCDOffset = readLong(dv, 8);
                return this.readZip64EOCD(reportedZ64EOCDOffset);
            }
        }
    }
    /**
     * Try and find the zip64 EOCD, starting from the given reported offset.
     * The actual EOCD block may start later in the file if there is prepended data.
     */
    private readZip64EOCD(offset: number) {
        // Read everything from the z64EOCDOffset to the zip 64 locator start, in case the archive
        // has prepended data. If there's a ton of prepended data or the zip64 EOCD block has a lot
        // of extensible data, we may end up reading a lot here...
        // We could add some fast-path cases to avoid that, but both of those are probably unlikely.
        return this.loadBlob(this.file.slice(offset, this.eocdOffset - z64LocatorLength)).then(
            (dv) => {
                this.parseZip64EOCD(dv, offset);
            },
        );
    }
    /**
     * Search through the given DataView for a zip64 EOCD block.
     *  signature                       4 bytes  (0x06064b50)
     *  size of zip64 end of central
     *  directory record                8 bytes
     *  version made by                 2 bytes
     *  version needed to extract       2 bytes
     *  number of this disk             4 bytes
     *  number of the disk with the
     *  start of the central directory  4 bytes
     *  total number of entries in the
     *  central directory on this disk  8 bytes
     *  total number of entries in the
     *  central directory               8 bytes
     *  size of the central directory   8 bytes
     *  offset of start of central
     *  directory with respect to
     *  the starting disk number        8 bytes
     *  zip64 extensible data sector    (variable size)
     */
    private parseZip64EOCD(dv: DataView, offset: number) {
        for (let i = 0; i < dv.byteLength - 55; i++) {
            if (dv.getUint32(i, true) === z64EOCDSig) {
                this.entryCount = readLong(dv, 32);
                this.cdSize = readLong(dv, 40);
                this.cdOffset = readLong(dv, 48);
                this.shift = i;
                this.z64EOCDOffset = offset + i;
                break;
            }
        }
    }
    private loadBlob(blob: Blob) {
        return new Promise<DataView>((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                resolve(new DataView(<ArrayBuffer>reader.result));
            });
            reader.addEventListener("error", reject);
            reader.readAsArrayBuffer(blob);
        });
    }
}

/**
 * Try and load all the file entries from the given zip file.  If any errors are encountered, they'll
 * be silently suppressed and an empty list will be returned.
 */
export function tryLoadEntries(file: File) {
    let pause = false;
    if (userHasSafari()) {
        // Safari fails to load object urls (e.g. new FileReader().readAsArrayBuffer
        pause = Multiplex.pause();
    }
    return new Zip(file)
        .loadEntries()
        .catch(() => <ZipEntry[]>[])
        .then((children) => {
            if (pause) {
                Multiplex.resume();
                pause = false;
            }
            return children;
        });
}
