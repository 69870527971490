import {
    DialogSize,
    H3,
    HeadingMargin,
    Icon,
    IconButton,
    InlineBanner,
    NonModal,
    PopoverMenu,
    PopoverMenuPlacement,
    Tooltip,
} from "design-system";
import { OracleSubmitForm } from "Everlaw/Oracle/OracleSubmitForm";
import { OracleEmptyTab, OracleProjectTab, OracleUserTab } from "Everlaw/Oracle/OracleTabs";
import {
    countQuestionsByProject,
    countQuestionsByUser,
    getIngestionStatus,
    OracleIngestionPipelineStatus,
} from "Everlaw/Oracle/OracleUtils";
import * as Perm from "Everlaw/PermissionStrings";
import * as Project from "Everlaw/Project";
import * as SearchResult from "Everlaw/SearchResult";
import { ReactWidget, wrapReactComponent } from "Everlaw/UI/ReactWidget";
import * as User from "Everlaw/User";
import * as React from "react";
import { ReactNode, useEffect, useId, useRef, useState } from "react";

export enum PanelType {
    Empty,
    User,
    Project,
}

interface PanelHeaderProps {
    panelType: PanelType;
    setPanelType: React.Dispatch<React.SetStateAction<PanelType | undefined>>;
}

function PanelHeader({ panelType, setPanelType }: PanelHeaderProps): ReactNode {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const infoIconRef = useRef<SVGSVGElement>(null);
    const userViewTooltipId = useId();
    const [showPopover, setShowPopover] = useState(false);
    const canAccessUserView = User.me.can(
        Perm.GENERATE_ORACLE,
        Project.getCurrentProject(),
        User.Override.ELEVATED_OR_ORGADMIN,
    );

    return (
        <div className={"flex-centered gap-8"}>
            <H3.Medium className={"margin-0"} marginType={HeadingMargin.NONE}>
                {panelType === PanelType.User ? "My questions" : "All questions"}
            </H3.Medium>
            {panelType === PanelType.User && (
                <>
                    <Icon.InfoCircle
                        ref={infoIconRef}
                        tabIndex={0}
                        aria-describedby={userViewTooltipId}
                        size={20}
                    />
                    <Tooltip id={userViewTooltipId} target={infoIconRef}>
                        {/*TODO: Oracle naming*/}
                        Users with View and Generate permissions on Project Query will be able to
                        see all your questions
                    </Tooltip>
                </>
            )}
            {canAccessUserView && (
                <>
                    <IconButton
                        ref={buttonRef}
                        onClick={() => setShowPopover((prevState) => !prevState)}
                        aria-label={"Change question view"}
                    >
                        <Icon.ChevronDown size={20} />
                    </IconButton>
                    <PopoverMenu
                        trigger={buttonRef}
                        show={showPopover}
                        setShow={setShowPopover}
                        placement={PopoverMenuPlacement.BOTTOM_START}
                    >
                        <PopoverMenu.Section>
                            <PopoverMenu.Option
                                selectable={true}
                                selected={panelType === PanelType.User}
                                label={"My questions"}
                                onClick={() => setPanelType(PanelType.User)}
                            />
                            <PopoverMenu.Option
                                selectable={true}
                                selected={panelType === PanelType.Project}
                                label={"All questions asked in project"}
                                onClick={() => setPanelType(PanelType.Project)}
                            />
                        </PopoverMenu.Section>
                    </PopoverMenu>
                </>
            )}
        </div>
    );
}

function getIngestionWarningText(ingestionStatus: OracleIngestionPipelineStatus | undefined) {
    switch (ingestionStatus) {
        case OracleIngestionPipelineStatus.INGESTING_FRESH:
            return (
                "Documents are currently being uploaded to this project. Questions cannot be "
                + "asked until the initial ingestion is complete."
            );
        case OracleIngestionPipelineStatus.INGESTING_UPDATE:
            return (
                "Documents are currently being uploaded to the project. Responses will only be "
                + "generated based on documents that have been completely uploaded to the project."
            );
        case OracleIngestionPipelineStatus.INACTIVE_EMPTY:
        case OracleIngestionPipelineStatus.INACTIVE_INGESTED:
        default:
            return null;
    }
}

interface OraclePanelProps {
    panelState: { show: boolean; searchId: SearchResult.Id | undefined };
}

export function OraclePanel({ panelState }: OraclePanelProps): ReactNode {
    const [panelType, setPanelType] = useState<PanelType | undefined>(undefined);
    const { setVisible, nonModalProps } = NonModal.use();
    useEffect(() => {
        setVisible(panelState.show);
    }, [panelState.show, setVisible]);

    const [ingestionStatus, setIngestionStatus] = useState<
        OracleIngestionPipelineStatus | undefined
    >(undefined);
    useEffect(() => {
        getIngestionStatus().then((ingestionStatus) => {
            setIngestionStatus(ingestionStatus);
        });
    }, []);

    const allProps = {
        ...nonModalProps,
        onHide: () => {
            panelState.show = false;
            setVisible(panelState.show);
        },
    };

    const userHasGeneratePerms = User.me.can(
        Perm.GENERATE_ORACLE,
        Project.getCurrentProject(),
        User.Override.ELEVATED_OR_ORGADMIN,
    );

    const [hasUserQuestions, setHasUserQuestions] = useState<boolean | undefined>(undefined);
    const [hasProjectQuestions, setHasProjectQuestions] = useState<boolean | undefined>(undefined);
    const [hasFetched, setHasFetched] = useState<boolean>(false);

    useEffect(() => {
        if (hasFetched) {
            return;
        }

        const initializeQuestions = async () => {
            const [projectQuestions, userQuestions] = await Promise.all([
                countQuestionsByProject(),
                countQuestionsByUser(),
            ]);

            setHasProjectQuestions(projectQuestions > 0);
            setHasUserQuestions(userQuestions > 0);
            setHasFetched(true);

            // Set initial panel state only after the first time question counts are fetched
            if (userHasGeneratePerms && userQuestions === 0) {
                setPanelType(PanelType.Empty);
            } else {
                setPanelType(PanelType.Project);
            }
        };

        initializeQuestions().catch((error) => {
            throw new Error(error);
        });
    }, [userHasGeneratePerms, hasFetched]);

    // Although the below TSX might look daunting, the use-case it supports is just the following:
    // 1. If the user has VIEW permissions, they are loaded into the Project panel and cannot
    //    navigate to the User panel.
    // 2. If the user has GENERATE permissions AND no questions have been asked on the project, they
    //    are loaded into the User panel and cannot navigate to the Project panel.
    // 3. If the user has GENERATE permissions AND they haven't asked any questions BUT others in
    //    the project have, they are loaded into the User panel and can navigate to the Project
    //    panel.
    // Additionally, for GENERATE users, if any ingestion is happening, a warning banner should be
    // shown in both the User and Project panels.

    return (
        <NonModal
            className={"oracle-main-dialog"}
            size={DialogSize.LARGE}
            // TODO: Oracle naming
            title={"Project Query"}
            minWidth={"100%"}
            minHeight={"99.9%"}
            {...allProps}
        >
            <main className={"oracle-main"}>
                {userHasGeneratePerms ? (
                    <>
                        {/*TODO: change the location/styling of this text banner with design feedback*/}
                        {(ingestionStatus === OracleIngestionPipelineStatus.INGESTING_FRESH
                            || ingestionStatus
                                === OracleIngestionPipelineStatus.INGESTING_UPDATE) && (
                            <InlineBanner icon={<Icon.InfoCircle />}>
                                {getIngestionWarningText(ingestionStatus)}
                            </InlineBanner>
                        )}
                        {panelType === PanelType.Empty && (
                            <OracleEmptyTab
                                hasUserQuestions={hasUserQuestions}
                                hasProjectQuestions={hasProjectQuestions}
                                ingestionStatus={ingestionStatus}
                                setHasUserQuestions={setHasUserQuestions}
                                setPanelType={setPanelType}
                            />
                        )}
                        <OracleSubmitForm
                            ingestionStatus={ingestionStatus}
                            searchId={panelState.searchId}
                            panelType={panelType}
                            setPanelType={setPanelType}
                        />
                        {panelType === PanelType.User && (
                            <OracleUserTab
                                panelHeader={
                                    <PanelHeader
                                        panelType={panelType}
                                        setPanelType={setPanelType}
                                    />
                                }
                                setHasQuestions={setHasUserQuestions}
                            />
                        )}
                        {panelType === PanelType.Project && (
                            <OracleProjectTab
                                panelHeader={
                                    <PanelHeader
                                        panelType={panelType}
                                        setPanelType={setPanelType}
                                    />
                                }
                                setHasQuestions={setHasProjectQuestions}
                            />
                        )}
                    </>
                ) : (
                    // VIEW users can only see the Project panel
                    panelType !== undefined && (
                        <OracleProjectTab
                            panelHeader={
                                <PanelHeader panelType={panelType} setPanelType={setPanelType} />
                            }
                            setHasQuestions={setHasProjectQuestions}
                        />
                    )
                )}
            </main>
        </NonModal>
    );
}

function createOracle(panelState: {
    show: boolean;
    searchId: SearchResult.Id | undefined;
}): ReactWidget<{ panelState: { show: boolean; searchId: SearchResult.Id | undefined } }> {
    return wrapReactComponent(OraclePanel, {
        panelState: panelState,
    });
}

/**
 * TODO: put these variables in a better place
 * I didn't know the right place to put this "global" state, so I put it in here just to get
 * something going. This should optimally be moved somewhere else.
 */
let oracleContainer: ReactWidget<OraclePanelProps> | undefined = undefined;
const oraclePanelState: { show: boolean; searchId: SearchResult.Id | undefined } = {
    show: false,
    searchId: undefined,
};

/**
 * Shows/hides the window's Oracle panel, initializing it if necessary.
 * This was made to accommodate the multiple entry points of Oracle: one through the header, and one
 * through the Results table toolbar.
 * If searchId is undefined, restricts all Oracle queries to only contain results from that search.
 * Otherwise, queries are allowed to contain results from any document in the corpus.
 */
export function toggleOracleVisible(searchId: SearchResult.Id | undefined) {
    if (!oracleContainer) {
        oracleContainer = createOracle(oraclePanelState);
    }
    oraclePanelState.show = !oraclePanelState.show;
    oraclePanelState.searchId = searchId;
    oracleContainer?.updateProps({ panelState: oraclePanelState });
}
