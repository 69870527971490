import Context = require("Everlaw/Context/Context");
import Database = require("Everlaw/Database");
import Project = require("Everlaw/Project");

export = new (class extends Context {
    private isInContext = false;
    constructor() {
        super(Context.AltContext.PRODADMIN);
    }
    declareInContext() {
        this.isInContext = true;
    }
    override inDatabaseContext(object: Project | Database) {
        return this.inServerContext();
    }
    override inServerContext() {
        return this.isInContext;
    }
})();
