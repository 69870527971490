import Base = require("Everlaw/Base");
import { Is } from "core";
import Perm = require("Everlaw/PermissionStrings");
import Project = require("Everlaw/Project");
import User = require("Everlaw/User");

// Name constants defined in "SbFreeService.java".
interface SbFreeGroup {
    name: string;
    hasDatabasePermissions: (user: User) => boolean;
}
const ADMIN: SbFreeGroup = {
    name: "SBFREE_ADMIN",
    hasDatabasePermissions: (user) => {
        return user.can(Perm.DB_ADMIN, Project.CURRENT);
    },
};
const BASIC: SbFreeGroup = {
    name: "SBFREE_BASIC",
    hasDatabasePermissions: (user) => {
        return (
            user.can(Perm.DB_UPLOAD, Project.CURRENT)
            && user.can(Perm.DB_DELETE, Project.CURRENT)
            && !user.can(Perm.DB_ADMIN, Project.CURRENT)
        );
    },
};

/**
 * Whether the user is in exactly one of the Storybuilder Free groups and has the proper database
 * permissions.
 */
export function inSbFreeGroup(user: User) {
    const sbFreeGroups = getSbFreeGroupsOfUser(user);
    if (!Is.defined(sbFreeGroups)) {
        return false;
    }
    return (
        (sbFreeGroups.adminId && ADMIN.hasDatabasePermissions(user))
        || (sbFreeGroups.basicId && BASIC.hasDatabasePermissions(user))
    );
}

/**
 * Gets the Storybuilder Free group for the user, or undefined if inconsistent.
 */
export function getSbFreeGroup(user: User) {
    const sbFreeGroups = getSbFreeGroupsOfUser(user);
    return sbFreeGroups && (sbFreeGroups.adminId || sbFreeGroups.basicId);
}

function getSbFreeGroupsOfUser(user: User) {
    if (user.groups.length !== 1) {
        return undefined;
    }
    return getSbFreeGroups(Base.get(User.Group, user.groups));
}

/**
 * Gets a map from 'adminId' and 'basicId' to the associated group (or undefined if it doesn't exist).
 */
export function getSbFreeGroups(groups = Base.get(User.Group)) {
    const admin = getAdminGroup(groups);
    const basic = getBasicGroup(groups);
    return {
        adminId: admin && admin.id,
        basicId: basic && basic.id,
    };
}

function getAdminGroup(groups: User.Group[]) {
    return getGroup(groups, ADMIN);
}

function getBasicGroup(groups: User.Group[]) {
    return getGroup(groups, BASIC);
}

function getGroup(groups: User.Group[], sbFreeGroup: SbFreeGroup) {
    for (const group of groups) {
        if (group.name === sbFreeGroup.name) {
            return group;
        }
    }
    return undefined;
}
