import * as Base from "Everlaw/Base";
import * as Rest from "Everlaw/Rest/Rest";

export default class ProcessingPrivateKey extends Base.Object {
    override id: number;
    databaseId: number;
    name: string;
    filename: string;
    fingerprint: string;

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }

    override get className(): string {
        return "ProcessingPrivateKey";
    }

    override display(): string {
        return this.name || this.filename;
    }

    override _mixin(params: any): void {
        Object.assign(this, params);
    }

    delete(): void {
        Rest.post("processing/deletePrivateKey.rest", {
            id: this.id,
        }).then(() => {
            Base.remove(this);
        });
    }
}
