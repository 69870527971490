import Base = require("Everlaw/Base");
import Database = require("Everlaw/Database");
import DateUtil = require("Everlaw/DateUtil");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import { Is } from "core";
import LabeledIcon = require("Everlaw/UI/LabeledIcon");
import Project = require("Everlaw/Project");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");
import SbFree = require("Everlaw/Context/SbFree");
import SingleSelect = require("Everlaw/UI/SingleSelect");
import { Str } from "core";
import UI_Validated = require("Everlaw/UI/Validated");
import TimezoneSelect = require("Everlaw/UI/TimezoneSelect");
import User = require("Everlaw/User");

interface CreateNewDialogParams {
    onSuccess?: (project: Project) => void;
    includeOwnerSelect?: boolean;
}

// Lazily load the owner select to avoid creating and destroying it for each new project dialog.
let ownerSelect: SingleSelect<User> = null;
function getOwnerSelect() {
    if (!ownerSelect) {
        ownerSelect = new SingleSelect({
            elements: Base.get(User),
            popup: "after",
            headers: false,
            placeholder: "Select project owner",
            onSelect: (user, isNew, selector) => {
                selector.minimize();
            },
        });
    }
    return ownerSelect;
}

export function createNewDialog(params: CreateNewDialogParams) {
    const newProjectDialog = new CreateNewDialog(params);
    newProjectDialog.show();
    newProjectDialog.focusProjectNameTextBox();
}

class CreateNewDialog extends QueryDialog {
    private projectNameTextBox: UI_Validated.Text;
    private timezoneSelect: SingleSelect<Base.Primitive<DateUtil.TimezoneN>>;
    private ownerSelect: SingleSelect<User>;
    constructor(params: CreateNewDialogParams) {
        super({
            title: "Create new project",
            prompt: "",
            body: Dom.div({ class: "sb-free-new-project-query-dialog-body" }),
            submitText: "Create project",
            onSubmit: () => {
                this.disableSubmit(true);
                const name = this.projectNameTextBox.getValue();
                if (Str.empty(name)) {
                    Dialog.ok("Error", "The name field cannot be empty.");
                    return false;
                }
                const timezone = this.timezoneSelect.getValue();
                if (!Is.defined(timezone)) {
                    Dialog.ok("Error", "Timezone is invalid.");
                    return false;
                }
                const owner = this.ownerSelect && this.ownerSelect.getSelected();
                Rest.post(`/parcel/${JSP_PARAMS.Server.defaultParcel}/sbFree/createProject.rest`, {
                    name,
                    timezoneId: timezone.id,
                    ownerId: owner && owner.id,
                }).then((data) => {
                    Base.set(Database, data.database);
                    const newProject = Base.set(Project, data.project);
                    params.onSuccess && params.onSuccess(newProject);
                });
                return true;
            },
        });
        this.buildProjectNameTextBox();
        this.buildTimeSelect();
        params.includeOwnerSelect && this.placeOwnerSelect(getOwnerSelect());
        this.refreshSubmit();
    }
    focusProjectNameTextBox() {
        this.projectNameTextBox.focus();
    }
    private buildProjectNameTextBox() {
        this._toDestroy.push(
            (this.projectNameTextBox = new UI_Validated.Text({
                // For SBFREE databases/projects we allow duplicate names, so we don't need to check
                // for uniqueness. However, this validated textbox still checks for name length, so
                // we keep it here.
                name: "project name",
                invalidMessage: "Project name already taken",
            })),
        );
        this.accessibleSubmit.addFormToValidated(this.projectNameTextBox);
        this.createSection("Project name", this.projectNameTextBox);
    }
    private buildTimeSelect() {
        this._toDestroy.push(
            (this.timezoneSelect = new TimezoneSelect.SelectN({
                onSelect: () => {
                    this.refreshSubmit();
                },
            })),
        );
        const header = new LabeledIcon("info-circle-20", {
            label: "Timezone",
            right: true,
            class: "label-right",
            tooltip: "You can change this setting later",
            tooltipPosition: ["after"],
        });
        this._toDestroy.push(header);
        const subheader = "What timezone should metadata be displayed in by default?";
        this.createSection(Dom.node(header), this.timezoneSelect, subheader);
    }
    private placeOwnerSelect(ownerSelect: SingleSelect<User>) {
        const subcontent = "This is optional and can be set on the Superuser page.";
        this.createSection("Project owner", (this.ownerSelect = ownerSelect), subcontent);
    }
    private createSection(header: Dom.Content, content: Dom.Nodeable, subheader?: Dom.Content) {
        const divContent = [
            Dom.div({ class: "sb-free-new-project-query-dialog-section-header" }, header),
            Dom.div(
                { class: "sb-free-new-project-query-dialog-section-content" },
                Dom.node(content),
            ),
        ];
        if (subheader) {
            divContent.splice(
                1,
                0,
                Dom.div({ class: "sb-free-new-project-query-dialog-section-subheader" }, subheader),
            );
        }
        Dom.create(
            "label",
            {
                class: "sb-free-new-project-query-dialog-section",
                content: divContent,
            },
            this.body,
        );
    }
    private refreshSubmit() {
        this.disableSubmit(!Is.defined(this.timezoneSelect.getValue()));
    }
}

export function getProjectsOwnedByMe() {
    return Base.get(Project).filter(isOwnedByMe);
}

export function isOwnedByMe(project: Project) {
    return (
        SbFree.inProjectContext(project)
        && project.sbFreeOwner
        && project.sbFreeOwner === User.me.id
    );
}
