import Rest = require("Everlaw/Rest");
import { whenPageVisible } from "Everlaw/DojoUtil";

/**
 * Simple abstraction which allows for repeatedly polling an endpoint.
 */
export class Poller {
    private timeout: number | null = null;

    constructor(
        private url: string, // endpoint to be polled
        private dataHandler: (data: unknown) => number, // returns time interval until next poll
        private onRejected?: (reason: any) => void | PromiseLike<void>,
    ) {}

    startPolling(): void {
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        Rest.get(this.url).then((data: unknown) => {
            const timeoutInterval = this.dataHandler(data);
            this.timeout = setTimeout(
                whenPageVisible(this.startPolling.bind(this)),
                timeoutInterval,
            );
        }, this.onRejected);
    }
}
