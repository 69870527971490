import Base = require("Everlaw/Base");
import Binder = require("Everlaw/Binder");
import Document = require("Everlaw/Document");
import Dom = require("Everlaw/Dom");
import Grids = require("Everlaw/Grids");
import Project = require("Everlaw/Project");
import Review_Base = require("Everlaw/Review/Base"); // Circular dependency - use for types only
import SearchResult = require("Everlaw/SearchResult");
import StatusUtil = require("Everlaw/SettingsPage/StatusUtil");
import Dialog = require("Everlaw/UI/Dialog");
import Win = require("Everlaw/Win");
import { objectToQuery } from "core";
import { BatchServiceStatuses } from "Everlaw/SettingsPage/StatusUtil";
import { Toolbar } from "Everlaw/Search/SearchPage";

// Base class for any page that displays a Grids.DocumentGrid and wants to be notified of review
// window activity
class BaseResultsPage {
    // Attempted use multiple times as the page loads, and occasionally on the first load(s) it
    // hasn't yet been defined by the Promise.
    batchServiceStatuses?: BatchServiceStatuses;
    resultsGrid: Grids.DocumentGrid = null;
    reviewWindow: Review_Base.ReviewWindow | null = null;
    /*
     * Filters can be applied to a search on a results page--when this happens, the actual search whose
     * results are being shown (i.e. the search after the filters are applied) is saved as this.search,
     * while the base search to which the filters were applied is saved as this.baseSearch. Note that
     * the url hash of the results page refers to the base search, not the search!
     */
    search: SearchResult = null;
    baseSearch: SearchResult = null;
    // A unique id to use for the review window.
    tabId: string | number = null;
    onLoadDoc: (docId: Document.Id) => void;
    constructor() {
        // Expose this search page so a review window can reference this ResultsPage through its
        // window.parent.
        (<any>window).EsiResultsPage = this;
        StatusUtil.getBatchServiceStatuses().then((statuses) => {
            this.batchServiceStatuses = statuses;
        });
    }
    addBinder(binder: any) {
        Base.set(Binder, JSON.parse(binder));
    }
    static openInNewDoc(url: string | URL, target: string): Review_Base.ReviewWindow | null {
        const windowObj = window.open(
            url,
            target,
            "resizable=yes,location=no,titlebar=yes,width=1000,height=740",
        ) as Review_Base.ReviewWindow | null;
        const isChromeiOS = navigator.appVersion.indexOf("CriOS") !== -1;
        if (!Win.isOpenWindow(windowObj)) {
            // Opening a new window in Chrome for iOS opens a new tab that isn't associated
            // with the current tab - although this is annoying, don't complain to the user.
            if (!isChromeiOS) {
                Dialog.ok(
                    "Popup blocker detected",
                    "Your browser appears to have a popup blocker. Please allow popups for "
                        + "this site and try this action again.",
                );
            }
        }
        return windowObj;
    }

    goToDoc(
        doc: Document,
        search: SearchResult,
        position: number[],
        onlyIfOpen?: boolean,
        extraParams?: Record<string, any>,
        forceRefresh = false,
    ) {
        let params: any = { doc: doc.id };
        if (search) {
            params.pos = position;
            params.search = search.id;
        }
        if (extraParams) {
            params = { ...params, ...extraParams };
        }
        const url = Project.CURRENT.url("review.do#" + objectToQuery(params));
        const target = "reviewWindow" + this.tabId;
        const openInNew = () => {
            if (onlyIfOpen) {
                return;
            }
            // This seems redundant, but it might help with the IE10 issue where the review window won't
            // open - perhaps explicitly calling close will allow us to reopen it.
            if (this.reviewWindow) {
                this.reviewWindow.close();
            }
            this.reviewWindow = BaseResultsPage.openInNewDoc(url, target);
        };
        if (!Win.isOpenWindow(this.reviewWindow)) {
            openInNew();
        } else {
            new Promise<void>((resolve, reject) => {
                try {
                    if (this.reviewWindow?.EsiReviewBase) {
                        this.reviewWindow.focus();
                        resolve(
                            this.reviewWindow.EsiReviewBase.loadingManager
                                .navigateConfirm(doc.id)
                                .then(() => {
                                    this.reviewWindow?.location.assign(url);
                                    forceRefresh && this.reviewWindow?.location.reload();
                                }),
                        );
                    } else {
                        this.reviewWindow?.location.assign(url);
                        resolve();
                    }
                } catch (e) {
                    openInNew();
                    resolve();
                }
            }).then(() => {
                if (this.reviewWindow) {
                    this.reviewWindow.focus();
                }
            });
        }
        return true;
    }

    // Handler for when the review window switches to a new document.
    loadDoc(
        positionJson: string,
        searchId: SearchResult.Id,
        newDocId: Document.Id,
        fromQuickReview = false,
    ) {
        // If the review page is coming from a different search, don't try and update that row
        if (!this.search || searchId !== this.search.id) {
            return;
        }
        // Position data passed as JSON to avoid issues with different Array prototypes per window
        const position = JSON.parse(positionJson);
        const oldrow = this.resultsGrid._grid.row(this.search.currDocId);
        if (oldrow.element) {
            Dom.removeClass(oldrow.element.firstChild, "current");
        }
        this.search.position = position;
        this.search.currDocId = newDocId;
        this.resultsGrid.updateRowById(newDocId);
        this.resultsGrid.scrollToCurrent();
        if (!fromQuickReview) {
            this.onLoadDoc && this.onLoadDoc(newDocId);
        }
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module BaseResultsPage {
    /** A Window with search results, e.g. the search page's results view. */
    export interface ResultsWindow extends Window {
        EsiResultsPage: BaseResultsPage;
    }
}

export = BaseResultsPage;
