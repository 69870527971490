import { containsIgnoreCase, Str } from "core";
import {
    DropdownMenu,
    H3,
    Icon,
    InlineBanner,
    InlineDropdownMenu,
    PaginationBar,
    PaginationBarBorder,
    Paragraph,
    TextButton,
    TextFilter,
    useBrandedCallback,
} from "design-system";
import * as Base from "Everlaw/Base";
import { useStore } from "Everlaw/Base";
import { Subscription } from "Everlaw/Multiplex";
import { OracleCard } from "Everlaw/Oracle/OracleComponents";
import { PanelType } from "Everlaw/Oracle/OraclePanel";
import {
    getCompletedQuestionsByProject,
    getCompletedQuestionsByUser,
    OracleInfo,
    OracleIngestionPipelineStatus,
    OracleQueryStep,
    subscribeToOracleByProject,
    subscribeToOracleByUser,
} from "Everlaw/Oracle/OracleUtils";
import { usePromise } from "Everlaw/Rest";
import * as User from "Everlaw/User";
import * as React from "react";
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

const PAGE_SIZE = 10;

enum DateSort {
    ASCENDING = "Sort by date (ascending)",
    DESCENDING = "Sort by date (descending)",
}

interface ExpandOrCollapseAllButtonsProps {
    onExpandAll: () => void;
    onCollapseAll: () => void;
    disableExpand: boolean;
    disableCollapse: boolean;
}

function ExpandOrCollapseAllButtons({
    onExpandAll,
    onCollapseAll,
    disableExpand,
    disableCollapse,
}: ExpandOrCollapseAllButtonsProps): ReactNode {
    return (
        <div>
            <TextButton disabled={disableExpand} onClick={onExpandAll}>
                Expand all
            </TextButton>
            <span aria-hidden={true}>/</span>
            <TextButton disabled={disableCollapse} onClick={onCollapseAll}>
                Collapse all
            </TextButton>
        </div>
    );
}

/**
 * Custom hook to manage Oracle information fetching and subscription.
 *
 * This hook handles the fetching of Oracle data based on cached IDs and manages
 * the subscription to updates. It uses a combination of external state from a
 * global store and local state to track the fetching status.
 *
 * @param fetchData - Function to fetch Oracle data for given IDs.
 * @param subscriptionFn - Function to subscribe to Oracle data updates and returns the
 *     subscription object.
 *
 * @returns An object containing Oracle info references, fetch status, and cached IDs.
 */
const useOracleInfo = (
    fetchData: (ids: number[]) => Promise<void>,
    subscriptionFn: () => Subscription,
) => {
    const oracleInfoRefs = useStore(Base.globalStore(OracleInfo));
    const [fetched, setFetched] = useState(false);
    const cachedIds = useMemo(
        () => oracleInfoRefs.map((ref) => ref.obj.id as number),
        [oracleInfoRefs],
    );

    useEffect(() => {
        const sub = subscriptionFn();
        return () => sub.close();
    }, [subscriptionFn]);

    usePromise({
        action: () => {
            if (fetched) {
                return Promise.resolve();
            } else {
                return fetchData(cachedIds);
            }
        },
        onResolve: () => setFetched(true),
        dependencies: [cachedIds, fetched, fetchData],
    });

    return { oracleInfoRefs, fetched, cachedIds };
};

export function OracleIntroInfo(): ReactNode {
    return (
        <>
            <div className={"centered"}>
                {/* alt text left empty since this isn't critical to experience or functionality */}
                <img src={"/images/oracle/oracle-ai-intro.svg"} alt={""} width={400} height={231} />
            </div>
            <H3 className={"centered"}>Ask questions about your case</H3>
            <Paragraph>
                {/*TODO: Oracle naming*/}
                Project Query will provide you responses with direct references to documents in your
                project, and can help build your case and generate relevant facts from documents.
            </Paragraph>
            <div>
                <Paragraph.Small>Sample questions:</Paragraph.Small>
                <ul className={"margin-0"}>
                    <li>
                        <Paragraph.Small>
                            What did Sam Jones say in her email about the 2020 Q2 financial report?
                        </Paragraph.Small>
                    </li>
                    <li>
                        <Paragraph.Small>
                            Which factors influenced John Smith’s decision to seek outside counsel
                            for the Q3 negotiations?
                        </Paragraph.Small>
                    </li>
                    <li>
                        <Paragraph.Small>
                            Who raised concerns about the revised scope for Acme Inc.’s funding
                            request?
                        </Paragraph.Small>
                    </li>
                </ul>
            </div>
            <InlineBanner icon={<Icon.InfoCircle />}>
                This is an experimental AI feature. Results may be inaccurate, incomplete, or
                misleading. Please double-check for accuracy.
            </InlineBanner>
        </>
    );
}

interface OracleTabProps {
    panelHeader: ReactNode;
    setHasQuestions: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export function OracleUserTab({ panelHeader, setHasQuestions }: OracleTabProps): ReactNode {
    const { oracleInfoRefs } = useOracleInfo(getCompletedQuestionsByUser, subscribeToOracleByUser);
    const userId = User.me.id;
    const [currentPage, setCurrentPage] = useState(1);
    const [dateSort, setDateSort] = useState<DateSort>(DateSort.DESCENDING);
    const hasRunEffect = useRef(false);

    const { inputValue, setInputValue, filterValue, setFilterValue, showInput, setShowInput } =
        TextFilter.use();

    const userQuestionInfos = useMemo(
        () => oracleInfoRefs.map((ref) => ref.obj).filter((info) => info.userId === userId),
        [oracleInfoRefs, userId],
    );

    useEffect(() => {
        setHasQuestions(userQuestionInfos.length > 0);
    }, [userQuestionInfos, setHasQuestions]);

    const sortedInfo = useMemo(
        () =>
            userQuestionInfos
                .filter(
                    (info) =>
                        containsIgnoreCase(info.questionSubmission.questionText, filterValue ?? "")
                        || containsIgnoreCase(
                            info.consolidatedAnswer?.answerText ?? "",
                            filterValue ?? "",
                        ),
                )
                .sort((a, b) =>
                    dateSort === DateSort.ASCENDING
                        ? a.questionSubmission.timestamp - b.questionSubmission.timestamp
                        : b.questionSubmission.timestamp - a.questionSubmission.timestamp,
                ),
        [userQuestionInfos, dateSort, filterValue],
    );

    useEffect(() => {
        setCurrentPage(1);
    }, [dateSort, filterValue, userId]);

    const numAnswers = sortedInfo.length;
    const pageIndex = currentPage - 1;
    const startIndex = pageIndex * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, numAnswers);

    const displayedInfo = useMemo(
        () => sortedInfo.slice(startIndex, endIndex),
        [sortedInfo, startIndex, endIndex],
    );

    const [openStates, setOpenStates] = useState<Record<number, boolean>>({});

    const toggleAll = useCallback(
        (open: boolean) => {
            const newStates: Record<number, boolean> = {};
            oracleInfoRefs.forEach((ref) => (newStates[ref.obj.id as number] = open));
            setOpenStates(newStates);
        },
        [oracleInfoRefs],
    );

    const toggleOpen = (id: number, open: boolean) => {
        setOpenStates((prev) => ({
            ...prev,
            [id]: open,
        }));
    };

    useEffect(() => {
        if (displayedInfo.length === 0 || hasRunEffect.current) {
            return;
        }
        const newStates: Record<number, boolean> = {};

        // default open if there are fewer than 3 questions
        displayedInfo.forEach(
            (info) =>
                (newStates[info.id as number] =
                    info.currentStep === OracleQueryStep.ANSWER_CONSOLIDATION
                        ? displayedInfo.length <= 2
                        : true),
        );
        setOpenStates(newStates);
        hasRunEffect.current = true;
    }, [displayedInfo]);

    const allOpen = useMemo(
        () => displayedInfo.every((info) => openStates[info.id as number]),
        [displayedInfo, openStates],
    );

    const allClosed = useMemo(
        () => displayedInfo.every((info) => !openStates[info.id as number]),
        [displayedInfo, openStates],
    );

    const dateDropdownSetValue = useBrandedCallback((val: DateSort | undefined) => {
        setDateSort(val ?? DateSort.DESCENDING);
    }, []);

    const searchFilterPlaceholder = "Search across questions";

    const {
        dropdownProps: dateDropdownProps,
        onItemClick: onDateSortClick,
        isItemSelected: isDateSelected,
    } = InlineDropdownMenu.use({
        filterable: false,
        readOnly: false,
        initialDisplay: dateSort,
        value: dateSort,
        setValue: dateDropdownSetValue,
    });

    const paginationBar = useMemo(
        () =>
            sortedInfo.length > PAGE_SIZE ? (
                <PaginationBar
                    currentPage={currentPage}
                    numTotalRows={numAnswers}
                    pageSize={PAGE_SIZE}
                    setCurrentPage={setCurrentPage}
                    disabled={false}
                    borderType={PaginationBarBorder.FULL}
                />
            ) : null,

        [sortedInfo, numAnswers, currentPage, setCurrentPage],
    );

    return (
        <div className={"oracle-flex-vertical gap-16 padding-bottom-16"}>
            <header className={"flex-horizontal"}>
                {panelHeader}
                <ExpandOrCollapseAllButtons
                    onExpandAll={() => toggleAll(true)}
                    onCollapseAll={() => toggleAll(false)}
                    disableExpand={allOpen}
                    disableCollapse={allClosed}
                />
            </header>
            <div className={"flex-vertical gap-8"}>
                <div className={"flex-horizontal"}>
                    <div className={"flex gap-8"}>
                        <InlineDropdownMenu
                            {...dateDropdownProps}
                            label={"Sort by date"}
                            width={"256px"}
                        >
                            <DropdownMenu.Section>
                                <DropdownMenu.Option
                                    selected={isDateSelected(DateSort.ASCENDING)}
                                    label={DateSort.ASCENDING}
                                    onClick={() => {
                                        onDateSortClick(DateSort.ASCENDING);
                                        dateDropdownProps.setShow(false);
                                    }}
                                />

                                <DropdownMenu.Option
                                    selected={isDateSelected(DateSort.DESCENDING)}
                                    label={DateSort.DESCENDING}
                                    onClick={() => {
                                        onDateSortClick(DateSort.DESCENDING);
                                        dateDropdownProps.setShow(false);
                                    }}
                                />
                            </DropdownMenu.Section>
                        </InlineDropdownMenu>
                    </div>
                    <TextFilter
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        setFilterValue={setFilterValue}
                        showInput={showInput}
                        setShowInput={setShowInput}
                        aria-label={searchFilterPlaceholder}
                        placeholder={searchFilterPlaceholder}
                    />
                </div>
                {/* TODO: ensure that this is correctly placed above the pagination bar*/}
                {filterValue && filterValue.length > 0 && (
                    <Paragraph.Small>
                        {numAnswers + " " + Str.pluralForm("result", numAnswers)}
                    </Paragraph.Small>
                )}
            </div>
            {paginationBar}
            {numAnswers > 0 ? (
                <div className={"oracle-flex-vertical gap-16"}>
                    {displayedInfo.map((info) => (
                        <OracleCard
                            key={info.id}
                            info={info}
                            toggleOpen={(open: boolean) => toggleOpen(info.id as number, open)}
                            open={openStates[info.id as number]}
                        />
                    ))}
                </div>
            ) : (
                <Paragraph className={"color-text-secondary"}>
                    {userQuestionInfos.length > 0
                        ? "No results found"
                        : "No questions have been asked yet"}
                </Paragraph>
            )}
            {paginationBar}
        </div>
    );
}

export function OracleProjectTab({ panelHeader, setHasQuestions }: OracleTabProps): ReactNode {
    const { oracleInfoRefs, fetched } = useOracleInfo(
        getCompletedQuestionsByProject,
        subscribeToOracleByProject,
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [dateSort, setDateSort] = useState<DateSort>(DateSort.DESCENDING);
    const hasRunEffect = useRef(false);

    const [filteredNames, setFilteredNames] = useState<ReadonlySet<string>>(new Set());
    const { dropdownProps, filterText, onItemClick, isItemSelected } = DropdownMenu.useMulti({
        filterable: true,
        values: filteredNames,
        setValues: setFilteredNames,
        placeholder: "Enter name",
    });

    // Since userNames is a Set, which don't work well in dependency arrays because of how Set
    // comparison is referential using Object.is, we have to do some extra logic for properly
    // tracking state updates. The userNamesUpdated variable below is used to only fetch userNames
    // on the initial component render and short-circuit on extra re-renders.
    const userNames = useMemo(
        () => new Set(oracleInfoRefs.map((ref) => ref.obj.questionSubmission.name)),
        [oracleInfoRefs],
    );

    const [userNamesUpdated, setUserNamesUpdated] = useState<boolean>(false);

    useEffect(() => {
        if (userNamesUpdated) {
            return;
        }
        setFilteredNames(userNames);
        if (fetched) {
            setUserNamesUpdated(true);
        }
    }, [fetched, userNamesUpdated, userNames]);

    const { inputValue, setInputValue, filterValue, setFilterValue, showInput, setShowInput } =
        TextFilter.use();
    const isAllChecked = userNames.size === filteredNames.size;

    const projectQuestionInfos = useMemo(
        () => oracleInfoRefs.map((ref) => ref.obj),
        [oracleInfoRefs],
    );

    useEffect(() => {
        setHasQuestions(projectQuestionInfos.length > 0);
    }, [projectQuestionInfos, setHasQuestions]);

    const sortedInfo = useMemo(
        () =>
            projectQuestionInfos
                .filter(
                    (info) =>
                        filteredNames.size === 0 || filteredNames.has(info.questionSubmission.name),
                )
                .filter(
                    (info) =>
                        containsIgnoreCase(info.questionSubmission.questionText, filterValue ?? "")
                        || containsIgnoreCase(
                            info.consolidatedAnswer?.answerText ?? "",
                            filterValue ?? "",
                        ),
                )
                .sort((a, b) =>
                    dateSort === DateSort.ASCENDING
                        ? a.questionSubmission.timestamp - b.questionSubmission.timestamp
                        : b.questionSubmission.timestamp - a.questionSubmission.timestamp,
                ),
        [projectQuestionInfos, dateSort, filteredNames, filterValue],
    );

    useEffect(() => {
        setCurrentPage(1);
    }, [dateSort, filteredNames, filterValue]);

    const numAnswers = sortedInfo.length;
    const pageIndex = currentPage - 1;
    const startIndex = pageIndex * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, numAnswers);

    const displayedInfo = useMemo(
        () => sortedInfo.slice(startIndex, endIndex),
        [sortedInfo, startIndex, endIndex],
    );

    const [openStates, setOpenStates] = useState<Record<number, boolean>>({});

    const toggleAll = useCallback(
        (open: boolean) => {
            const newStates: Record<number, boolean> = {};
            oracleInfoRefs.forEach((ref) => (newStates[ref.obj.id as number] = open));
            setOpenStates(newStates);
        },
        [oracleInfoRefs],
    );

    const toggleOpen = (id: number, open: boolean) => {
        setOpenStates((prev) => ({
            ...prev,
            [id]: open,
        }));
    };

    useEffect(() => {
        if (displayedInfo.length === 0 || hasRunEffect.current) {
            return;
        }
        const newStates: Record<number, boolean> = {};

        // Default open if there are fewer than 3 answers.
        // Answers that are being generated are open on render
        displayedInfo.forEach(
            (info) =>
                (newStates[info.id as number] =
                    info.currentStep === OracleQueryStep.ANSWER_CONSOLIDATION
                        ? displayedInfo.length <= 2
                        : true),
        );
        setOpenStates(newStates);
        hasRunEffect.current = true;
    }, [displayedInfo]);

    const allOpen = useMemo(
        () => displayedInfo.every((info) => openStates[info.id as number]),
        [displayedInfo, openStates],
    );

    const allClosed = useMemo(
        () => displayedInfo.every((info) => !openStates[info.id as number]),
        [displayedInfo, openStates],
    );

    const dateDropdownSetValue = useBrandedCallback((val: DateSort | undefined) => {
        setDateSort(val ?? DateSort.DESCENDING);
    }, []);

    const searchFilterPlaceholder = "Search across questions";

    const {
        dropdownProps: dateDropdownProps,
        onItemClick: onDateSortClick,
        isItemSelected: isDateSelected,
    } = InlineDropdownMenu.use({
        filterable: false,
        readOnly: false,
        initialDisplay: dateSort,
        value: dateSort,
        setValue: dateDropdownSetValue,
    });

    const paginationBar = useMemo(
        () =>
            sortedInfo.length > PAGE_SIZE ? (
                <PaginationBar
                    currentPage={currentPage}
                    numTotalRows={numAnswers}
                    pageSize={PAGE_SIZE}
                    setCurrentPage={setCurrentPage}
                    disabled={false}
                    borderType={PaginationBarBorder.FULL}
                />
            ) : null,
        [sortedInfo, currentPage, setCurrentPage, numAnswers],
    );

    return (
        <div className={"oracle-flex-vertical gap-16 padding-bottom-16"}>
            <header className={"flex-horizontal"}>
                {panelHeader}
                <ExpandOrCollapseAllButtons
                    onExpandAll={() => toggleAll(true)}
                    onCollapseAll={() => toggleAll(false)}
                    disableExpand={allOpen}
                    disableCollapse={allClosed}
                />
            </header>
            <div className={"flex-vertical gap-8"}>
                <div className={"flex-horizontal"}>
                    <div className={"flex gap-8"}>
                        <InlineDropdownMenu
                            {...dateDropdownProps}
                            label={"Sort by date"}
                            width={"256px"}
                        >
                            <DropdownMenu.Section>
                                <DropdownMenu.Option
                                    selected={isDateSelected(DateSort.ASCENDING)}
                                    label={DateSort.ASCENDING}
                                    onClick={() => {
                                        onDateSortClick(DateSort.ASCENDING);
                                        dateDropdownProps.setShow(false);
                                    }}
                                />

                                <DropdownMenu.Option
                                    selected={isDateSelected(DateSort.DESCENDING)}
                                    label={DateSort.DESCENDING}
                                    onClick={() => {
                                        onDateSortClick(DateSort.DESCENDING);
                                        dateDropdownProps.setShow(false);
                                    }}
                                />
                            </DropdownMenu.Section>
                        </InlineDropdownMenu>
                        <DropdownMenu
                            label={"Filter by user"}
                            horizontal={true}
                            {...dropdownProps}
                            width={"150px"}
                        >
                            <DropdownMenu.Section>
                                <DropdownMenu.Checkbox
                                    value={isAllChecked}
                                    label={"(All)"}
                                    onChange={() =>
                                        setFilteredNames(() => {
                                            if (isAllChecked) {
                                                return new Set();
                                            } else {
                                                return userNames;
                                            }
                                        })
                                    }
                                />
                            </DropdownMenu.Section>
                            <DropdownMenu.Section>
                                {[...userNames]
                                    .filter((name) => containsIgnoreCase(name, filterText ?? ""))
                                    .map((name) => (
                                        <DropdownMenu.Checkbox
                                            key={name}
                                            label={name}
                                            ellipsify={true}
                                            onChange={() => onItemClick(name)}
                                            value={isItemSelected(name)}
                                        />
                                    ))}
                            </DropdownMenu.Section>
                        </DropdownMenu>
                    </div>
                    <TextFilter
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        setFilterValue={setFilterValue}
                        showInput={showInput}
                        setShowInput={setShowInput}
                        aria-label={searchFilterPlaceholder}
                        placeholder={searchFilterPlaceholder}
                    />
                </div>
                {/* TODO: ensure that this is correctly placed above the pagination bar*/}
                {filterValue && filterValue.length > 0 && (
                    <Paragraph.Small>
                        {numAnswers + " " + Str.pluralForm("result", numAnswers)}
                    </Paragraph.Small>
                )}
            </div>
            {paginationBar}
            {numAnswers > 0 ? (
                <div className={"oracle-flex-vertical gap-16"}>
                    {displayedInfo.map((info) => (
                        <OracleCard
                            key={info.id}
                            open={openStates[info.id as number]}
                            toggleOpen={(open: boolean) => toggleOpen(info.id as number, open)}
                            info={info}
                        />
                    ))}
                </div>
            ) : (
                <Paragraph className={"color-text-secondary"}>
                    {projectQuestionInfos.length > 0
                        ? "No results found"
                        : "No questions have been asked yet"}
                </Paragraph>
            )}
            {paginationBar}
        </div>
    );
}

interface OracleEmptyTabProps {
    hasUserQuestions: boolean | undefined;
    hasProjectQuestions: boolean | undefined;
    ingestionStatus: OracleIngestionPipelineStatus | undefined;
    setHasUserQuestions: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setPanelType: React.Dispatch<React.SetStateAction<PanelType | undefined>>;
}

export function OracleEmptyTab({
    hasUserQuestions,
    hasProjectQuestions,
    ingestionStatus,
    setHasUserQuestions,
    setPanelType,
}: OracleEmptyTabProps): ReactNode {
    // Set up user subscription information here too to enable live query updates
    const { oracleInfoRefs } = useOracleInfo(getCompletedQuestionsByUser, subscribeToOracleByUser);
    const userId = User.me.id;

    const userQuestionInfos = useMemo(
        () => oracleInfoRefs.map((ref) => ref.obj).filter((info) => info.userId === userId),
        [oracleInfoRefs, userId],
    );

    useEffect(() => {
        setHasUserQuestions(userQuestionInfos.length > 0);
    }, [userQuestionInfos, setHasUserQuestions]);

    return (
        <>
            {!hasUserQuestions && (
                <>
                    {hasProjectQuestions && (
                        <>
                            <div className={"flex-vertical flex-end"}>
                                <TextButton onClick={() => setPanelType(PanelType.Project)}>
                                    View all user questions
                                </TextButton>
                            </div>
                        </>
                    )}
                    {ingestionStatus !== OracleIngestionPipelineStatus.INACTIVE_EMPTY && (
                        <OracleIntroInfo />
                    )}
                </>
            )}
        </>
    );
}
