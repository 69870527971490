import { EverColor } from "design-system";
import Dom = require("Everlaw/Dom");
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import SbFree = require("Everlaw/Context/SbFree");
import SbFreeLimitedFeatureElements = require("Everlaw/Context/SbFree/LimitedFeature/Elements");
import SbFreeProject = require("Everlaw/Context/SbFree/Project");

/**
 * The project select header on Storybuilder Free projects consists of two sections: projects owned
 * by the user and other projects they're in. Below the list of projects they own, there is a
 * "+ Create new project" row to allow them to create new projects. The project select is created
 * as normal if not in a Storybuilder Free context.
 */
export = SbFree.singleSelect<Project>({
    newClassesInline: {
        "Owned by me": {
            createNew: (selector) => {
                selector.minimize();
                // We need to use the current project to reach this endpoint on the profile page.
                // Note that if we are on the profile page without any projects, the project select
                // will not be shown. Therefore, we don't have to worry about Project.CURRENT being
                // undefined.
                const url = Project.CURRENT.url("sbFree/getProjectOwnershipLimit.rest");
                Rest.get(url).then((limit: number) => {
                    const myProjects = SbFreeProject.getProjectsOwnedByMe();
                    if (myProjects.length >= limit) {
                        Rest.post("/users/hitStoryLimits.rest");
                        showProjectLimitExceededDialog(limit);
                    } else {
                        SbFreeProject.createNewDialog({
                            onSuccess: (project) => project.goto("home"),
                        });
                    }
                });
            },
            color: EverColor.EVERBLUE_40,
            classDisplay: "project",
        },
    },
    getHeader: (p) => {
        if (SbFreeProject.isOwnedByMe(p)) {
            return "Owned by me";
        } else {
            return "Shared with me";
        }
    },
    classOrder: ["Owned by me", "Shared with me"],
});

function showProjectLimitExceededDialog(limit: number) {
    const banneredDialog = new SbFreeLimitedFeatureElements.BanneredDialog({
        dialogParams: {
            title: "Unable to create new project",
            prompt: Dom.div({ style: { marginBottom: "16px" } }, [
                Dom.span({ class: "semi-bold" }, `You can only own up to ${limit} projects.`),
                " If you want to create a new project, you must delete a project that you own.",
            ]),
            cancelText: undefined,
            submitText: "OK",
            style: { width: "500px" },
        },
        bannerParams: {
            content: [
                Dom.span({ class: "semi-bold" }, "Enjoying Storybuilder by Everlaw? "),
                "Achieve more with projects by accessing all features ",
                { openRequestDemoModal: true },
            ],
            /** Destroying the dialog so that it does not interfere with the "Request a demo" modal */
            onLinkClick: () => {
                banneredDialog.destroy();
            },
        },
        bannerArea: "body",
    });

    banneredDialog.show();
}
