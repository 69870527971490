import Rest = require("Everlaw/Rest");
import { Constants, Str } from "core";

function getCurrentPage() {
    return Str.substrBetweenLast("/", ".do", location.href);
}

function getCurrentTabOrView() {
    if (Str.containsCaseSensitive(location.href, "tab=")) {
        return Str.substrBetweenLast("tab=", "&", location.href);
    } else if (Str.containsCaseSensitive(location.href, "view=")) {
        return Str.substrBetweenLast("view=", "", location.href);
    }
    {
        return "";
    }
}

/**
 * Product wants some tabs and pages to be treated in special ways. For example, there are a few
 * settings-like pages across the site that we're going to track as if they were settings.do
 */
function productSpecificModifications(p: string, t: string) {
    // treat assigned review time separately
    if (p === "review" && Str.containsCaseSensitive(location.href, "assignment=")) {
        return "review-ASSIGNED";
    }
    // treat these page/tab combinations as an umbrella project settings page
    if (
        (p === "data" && t === "advanced-settings")
        || (p === "data" && t === "production-notifications")
    ) {
        return "settings";
    }
    // treat these data.do pages as "productions"
    if (
        (p === "data" && t === "productions")
        || (p === "data" && t === "create-production-protocol")
        || (p === "data" && t === "production-protocols")
        || (p === "data" && t === "modify-production-config")
        || (p === "data" && t === "produce-form")
    ) {
        return "productions";
    } else if (p === "data") {
        // the default data.do page will be considered "uploads"
        return "uploads";
    }
    // treat the datavis view as it's own page
    if (p === "search" && Str.startsWith(t, "datavis")) {
        return "dataVis";
    }
    // treat the pc model tab as it's own page, tabName is in the form modelXYZ
    if (
        (p === "analytics" && Str.startsWith(t, "model"))
        || (p === "analytics" && t === "prediction-wizard")
    ) {
        return "pcModel";
    }

    return p;
}

export class TimeLogger {
    private static BUFFER_TIME = 5 * Constants.SEC;
    private window: Window;
    private lastLogTime: number;

    constructor(window: Window) {
        this.window = window;
        this.lastLogTime = performance.now();
        for (const action of ["load", "click", "keyup", "wheel"]) {
            window.addEventListener(action, (_) => {
                if (
                    action === "load"
                    || performance.now() - this.lastLogTime > TimeLogger.BUFFER_TIME
                ) {
                    const modifiedPageName = productSpecificModifications(
                        getCurrentPage(),
                        getCurrentTabOrView(),
                    );
                    // Since this Rest request gets called on every user action, it can fail for numerous reasons.
                    // If a user's connectivity is bad, if the server goes down, if they get logged out due to
                    // inactivity...etc. Currently we catch the errors and just silence them. Originally we
                    // generated bugsnags on failures but this created thousands of unactionable bug reports.
                    Rest.post("logAction.rest", { pageName: modifiedPageName }).catch(() => {});
                    this.lastLogTime = performance.now();
                }
            });
        }
    }
}
