/**
 * Currently four billing modes are supported:
 * Standard and total storage, each with the option to exclude produced documents.
 * Standard: processsed and produced data is charged at full size and native data is charged at native size.
 * Total storage: everything is charged at full size.
 * The Excluding_Produced suffix means that produced documents are not counted towards the billable size.
 */
export enum BillingMode {
    STANDARD = "STANDARD",
    STANDARD_EXCLUDING_PRODUCED = "STANDARD_EXCLUDING_PRODUCED",
    TOTAL_STORAGE = "TOTAL_STORAGE",
    TOTAL_STORAGE_EXCLUDING_PRODUCED = "TOTAL_STORAGE_EXCLUDING_PRODUCED",
}
