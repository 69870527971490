import * as Base from "Everlaw/Base";
import * as Project from "Everlaw/Project";
import * as Rest from "Everlaw/Rest";
import {
    DocumentS3Source,
    DocumentS3SourceRestData,
    LocalStatus,
    setDirInfo,
} from "Everlaw/Model/Upload/DocumentS3Source";
import { NativeTransferId } from "Everlaw/Model/Upload/NativeTransfer";
import { SourceState } from "Everlaw/Model/Upload/S3Source";

export interface DatasetSourceRestData extends DocumentS3SourceRestData {
    datasetId: number;
    documentId: number;
    nativeTransferId?: NativeTransferId;
    eql?: string;
    numDocs?: number;
}

/**
 * Contains upload config and S3 source file information for a file in a dataset that
 * is uploaded through Native Upload.
 * A front-end correspondence of DatasetSource.java.
 */
export class DatasetSource extends DocumentS3Source {
    get className(): string {
        return "DatasetSource";
    }
    datasetId: number;
    documentId: number;
    nativeTransferId: NativeTransferId;
    eql: string;
    numDocs: number;

    constructor(data: DatasetSourceRestData) {
        super(data);
    }

    completeUpload(etags: string[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Rest.post("processing/signComplete.rest", {
                datasetId: this.datasetId,
                s3Key: this.s3Key,
                uploadId: this.s3UploadId,
                etags,
                sha1Hash: this.sha1Hash,
            }).then(
                () => {
                    this.state = SourceState.PROCESSING;
                    Base.publish(this);
                    resolve();
                },
                (e) => {
                    reject(e);
                    throw e;
                },
            );
        });
    }

    isAborted(): boolean {
        return this.localStatus === LocalStatus.STOPPED;
    }

    isUploadComplete(): boolean {
        return (
            (!this.localStatus || this.localStatus === LocalStatus.COMPLETED)
            && this.state !== SourceState.UPLOADING
        );
    }

    uploading(): boolean {
        return this.state === SourceState.UPLOADING;
    }

    setDirInfo(): void {
        setDirInfo(this, false);
    }

    downloadUrl(): string {
        return Project.CURRENT.url("processing/downloadNativeSource.do?sourceId=" + this.id);
    }
}
